<section class="home-banner overflow-hidden">
    <div class="container-fluid">
        <div class="row mx-0">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <div class="row d-flex align-items-center">
                    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center flex-wrap hero-container">
                        
                        <div class="home-banner-content">
                            <h6 class="heading-6 tallyfont">Tally Plugin for Document Management</h6>
                            <h1 class="heading-1">Turbocharge  <span class="underline-highlight"> Tally with dox2U! </span></h1>
                            
                            <div class="hero-text-align">                           
                                <p>Easy cloud based document management within Tally transactions. Link, control, 
                                    and access your documents seamlessly.</p>
                            </div>
                     

                            <div class="d-flex home-banner-btns">
                                <a><button class="button-primary trydox cursor-pointer" (click)="scrollToDownloadTallyForm()">Try for free</button></a> &nbsp;
                                <a href=" https://calendly.com/dox2u-dms/dox2u-tally-plugin-demo-ws" target="_blank" class="underlineNone"><button
                                        class="button-transparent btn-schedule d-flex align-items-center"><img src="../../../assets/Image/sche.svg"
                                            alt="" loading="lazy"> Schedule a demo</button></a>
                            </div>
                        </div>
                        
                    </div>

                    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center">
                        <div class="home-banner-hero-img d-flex" data-aos="fade-left" data-aos-duration="2500">
                            <img src="../../../assets/Image/tally_DMS.png" alt="document management system" class="img-fluid" loading="lazy"
                                >
                        </div>
                    </div>
                    <!-- <div class="col-xxl-1"></div> -->
                    <div class="manage ">
                        <p class="businessfont  font992"> Trusted by 10,000+ businesses to manage their documents</p>
                    </div>
                </div>
               
              
            
            </div>

            
        </div>

     
    </div>
    <div class="row col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto">
    <div class="d-flex align-items-center justify-content-between displayChange1 clientlogo-container w-100" style="margin-top: 69px;">
        <div class="d-flex flex-column align-items-center">
            <img src="../../../assets/Image/clientLogo/jimmy-logo.png" class="img-fluid" loading="lazy">
            <p>Radiohead brand private limited</p>
        </div>
        <div class="d-flex flex-column align-items-center">
            <img src="../../../assets/Image/clientLogo/salon-logo.png" class="img-fluid" loading="lazy">
            <p>Salon Basics</p>
        </div>
        <div class="d-flex flex-column align-items-center">
            <img src="../../../assets/Image/clientLogo/FM-logo.png" class="img-fluid" loading="lazy">
            <p>Full Throttle media LLC</p>
        </div>

        <div class="d-flex flex-column align-items-center">
            <img src="../../../assets/Image/clientLogo/stride-logo.png" class="img-fluid" loading="lazy">
            <p>Stride Ventures</p>
        </div>
        <div class="d-flex flex-column align-items-center">
            <img src="../../../assets/Image/clientLogo/hummel-logo.png" class="img-fluid" loading="lazy">
            <p>Hummel India Private Limited</p>
        </div>
        <div class="d-flex flex-column align-items-center displayHideRadicura">
            <img src="../../../assets/Image/clientLogo/radicura-logo.png" class="img-fluid" loading="lazy">
            <p>Radicura Pharmaceuticals Private Limited</p>
        </div>
        <div class="d-flex flex-column align-items-center displayHideKMC">
            <img src="../../../assets/Image/clientLogo/KMC-logo.png" class="img-fluid" loading="lazy">
            <p>KMC Aluminium Pvt Ltd</p>
        </div>

        
    </div>
    <marquee direction="left">
    <div class="d-flex align-items-center justify-content-between displayRespMarquee mx-auto" style="margin-top: 60px;">
        
        <img src="../../../assets/Image/clientLogo/jimmy.png" loading="lazy">
        <img src="../../../assets/Image/clientLogo/salonBasics.png" loading="lazy">
        <img src="../../../assets/Image/clientLogo/FT.png" loading="lazy">
        <img src="../../../assets/Image/clientLogo/strideV.png" loading="lazy">
        <img src="../../../assets/Image/clientLogo/hummel.png" loading="lazy">
        <img src="../../../assets/Image/clientLogo/radicura.png" loading="lazy">
        <img src="../../../assets/Image/clientLogo/KMC.png" loading="lazy">
    </div>
    </marquee>
</div>
</section>


<section class="banner-new">
    <div class="container">
    <div class="row">
        <div class=" col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto">
            <div class="tabs-section-Tbar ">
                <h3 class="size-fourty mb-3">See how it works!</h3>
                
            </div>
    <div class="row banner-new-container">
        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <div class="integration-doc">
                <!-- <iframe width="803" height="452" [src]="videoUrl" frameborder="0" allowfullscreen></iframe> -->
                 <video id="myVideo" controls (click)="pauseVideo()">
        <source [src]="videoUrl" type="video/mp4">
        Your browser does not support the video tag.
      </video>
            </div>
        </div>
       
    </div>
        </div>
    </div>
    
    </div>
    <div class="gradient-divider"></div>
    </section>
    

    <section id="downloadForm" class="download-and-install top-padd bottom-padd common-padd-tallyForm ">
        <div class="container">
            <div class="row">
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto dms-header-container">
                    <p class="dms-header1">DOWNLOAD PLUGIN</p>
                    <h3 class="size-fourty mb-3">Get dox2U’s Tally Plugin Now!</h3>
                    <p class="common-heading" style="text-align: center;">Getting started with dox2U’s Tally Plugin is a simple two-step process. 
                        You can DIY or <a href="https://calendly.com/dox2u-dms/tally-plugin-installation" target="_blank" class="schedule-call"> schedule a call</a> with one of our experts.</p>
                </div>
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto">
                    <div class="d-flex justify-content-between align-items-start flex-wrap" style="row-gap: 50px;">
                        <div
                            class="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 d-flex flex-column justify-content-start t-cen centText p-0">
    
                            <h3 class="dmsForm-step1">Step 1</h3>
                            <h5 class="dmsForm-h5">Create a dox2U workspace</h5>
                            <p class="para-common">A <span>dox2U workspace</span> is a private secured space on dox2U’s cloud where all your documents are organized and stored for your team’s access only.</p>
                            <div>
                                <div class="d-flex ">
                                    <!-- <img src="../../../assets/Image/dcheck.svg" class="mright mb-3 pb-1"> -->
                                    
                                    <p class="common-para text1 wunset"> <span class="horizontal-line"> </span> 15 days Free Trial</p>
                                </div>
                                <div class="d-flex ">
                                    <!-- <img src="../../../assets/Image/dcheck.svg" class="mright mb-3 pb-1"> -->
                                    <p class="common-para text1 wunset"><span class="horizontal-line"> </span>No credit card needed</p>
                                </div>
                                <div class="d-flex ">
                                    <!-- <img src="../../../assets/Image/dcheck.svg" class="mright mb-3 pb-1"> -->
                                    <p class="common-para text1 wunset"><span class="horizontal-line"> </span>Multiple plans to choose from</p>
                                </div>
                                
                            </div>
    
                            <!-- </div> -->
                            <!-- <p class="itelictext-adon "><i class=" windo-10">Supports Tally ERP 9, Tally Prime (Single and
                                    Multi
                                    User) and Tally on Cloud</i>
                            </p> -->
                            <div class="getStarted-btn">
                                <button class="button-primary my-2 my-sm-0 cursor-pointer gs-link" (click)="scrollToGettingStarted()" >
                                    Get Started
                                  </button>
                            </div>
                        </div>
                        <!-- <div class="col-xxl-1 d-xl-none d-xxl-block d-lg-none d-md-none d-sm-none"></div> -->
                        <div
                            class="tallyDMSForm col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 p-0 d-flex flex-column justify-content-center artopD">
                            <h3 class="dmsForm-step1">Step 2</h3>
                            <h5 class="dmsForm-h5">Download Tally plugin</h5>
                            <p class="para-common">Our Plugin supports Tally ERP 9, Tally Prime and Tally on Cloud</p>
                            <div id="tallyDMSshow" *ngIf="!submittedForm">
                                <form [formGroup]="dmsForm" (ngSubmit)="onSubmit()">
                                    <div class="d-flex flex-column colmG">
                                        <div class="d-flex form-container w-100">
                                            <div class="d-flex flex-column w-50 input-container">
                                                <div class="nameInput d-flex flex-column form-group">
                                                    <label>
                                                        <p class="label-field mb-1 tunset">First Name<span class="text-danger"
                                                            >*</span>
                                                        </p>
                                                    </label>
                                                    <input class="form-control" formControlName="firstname" id="firstname" name="firstname" placeholder="Enter first name" (keypress)="resetFirstNameDiv($event)">
                                                    
                                                        <!-- <div *ngIf="dmsForm.get('firstname')?.hasError('required') && dmsForm.get('firstname').touched" class="error">This field cannot be empty</div> -->
                                                </div>
                                                <p class="mb-0 meserr text-danger" *ngIf="firstNameValidDiv">
                                                    This field cannot be empty
                                                  </p>
                                                  
                                                <p id="errfirstname1" class="mb-0  meserr text-danger"></p>
                                            </div>
                                            <div class="d-flex flex-column w-50 input-container">
                                                <div class="nameInput d-flex flex-column form-group">
                                                    <label>
                                                        <p class="label-field mb-1 tunset">Last Name<span class="text-danger"
                                                                >*</span></p>
                                                    </label>
                                                    <input class="form-control" formControlName="lastname" id="lastname" placeholder="Enter last name" name="lastname" (keypress)="resetLastNameDiv($event)">
                                                </div>
                                                <p class="mb-0 meserr text-danger" *ngIf="lastNameValidDiv">
                                                    This field cannot be empty
                                                  </p>
                                                <p id="errlastname1" class="mb-0  meserr"></p>
                                            </div>
                                        </div>
                                        <div class="d-flex form-container w-100">
    
                                            <div class="d-flex flex-column w-50 input-container">
                                                <div class="nameInput d-flex flex-column form-group">
                                                    <label>
                                                        <p class="label-field mb-1 tunset">Email Address<span
                                                                style="color:red">*</span>
                                                        </p>
                                                    </label>
                                                    <input class="form-control" formControlName="email" id="tallyemail" placeholder="Enter your email address" name="tallyemail" (keypress)="resetEmailDiv($event)">
                                                </div>
                                                <p class="mb-0 meserr text-danger" *ngIf="emailValidDivEmpty">
                                                    This field cannot be empty
                                                  </p>
                                                  <p class="mb-0 meserr text-danger" *ngIf="emailValidDivInvalid">
                                                    Please enter valid email
                                                  </p>
                                                  
                                               
                                            </div>
                                            <div class="d-flex flex-column w-50 input-container">
                                                <div class="nameInput d-flex flex-column form-group">
                                                    <label class="mb-0">
                                                        <p class="label-field phn-label tunset">Phone Number<span class="text-danger"
                                                            >*</span></p>
                                                    </label>

                                                    <!-- <input name="phone" type="tel" id="phoneC" 
                                                        class="getting-started-input phn-inp inp100 form-control" placeholder="0987654321" formControlName="phone" (input)="resetPhoneDiv($event)"
                                                        
                                                        maxlength="17"> -->
                                                        <ngx-intl-tel-input class="getting-started-input phn-inp inp100 form-control" (input)="resetPhoneDiv($event)"
                                                        [cssClass]="'custom'"
                                                      
                                                        [enableAutoCountrySelect]="true"
                                                        [enablePlaceholder]="true"
                                                        [enableAutoCountrySelect]="true"
                                                        [searchCountryFlag]="true"
                                                        [searchCountryField]="[
                                                          SearchCountryField.Iso2,
                                                          SearchCountryField.Name
                                                        ]"
                                                        [selectFirstCountry]="false"
                                                        [selectedCountryISO]="CountryISO.India"
                                                        [maxLength]="15"
                                                        [phoneValidation]="true"
                                                        [separateDialCode]="separateDialCode"
                                                        [numberFormat]="PhoneNumberFormat.National"
                                                        name="phone"
                                                        formControlName="phone"
                                                        (countryChange)="onCountryChange($event)"
	></ngx-intl-tel-input>
                                                </div>
                                                <p class="mb-0 meserr text-danger" *ngIf="phoneValidDiv">
                                                    This field cannot be empty
                                                  </p>

                                                  <p class="mb-0 meserr text-danger" *ngIf="phoneValidationCheckDiv">
                                                    Please enter the valid number
                                                  </p>
    
                                            </div>
                                        </div>
                                        <div class="justify-content-center align-items-center d-flex download-button">
                                            <button class="button-primary trydox" id="myButton" type="submit" [disabled]="isLoading"><span
                                                    class="hideDownload">Download</span> </button>
                                            <div id="loader" class="spinner-border text-white"
                                                style="display:none;position:absolute;" role="status">
                                                <span class="sr-only"></span>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div id="tallyDMShide" *ngIf="submittedForm" class="d-flex  flex-column justify-content-center align-items-center"
                                >
                                
                                <img src="../../../assets/Image/tallysuccess.svg" loading="lazy">
                                <p class="common-para text-center" style="margin-top: 32px;">Thank you! We’ve sent you an email with a link for downloading our Tally Plugin.</p>
                            </div>
<!--     
                            <i class="textbot  sales-support">For Sales & Support : <span class="textbot-number">
                                +91
                                8448857421 </span> </i> -->
    
                        </div>
    
                    </div>
                </div>
            </div>
        </div>
    </section>
<!-- 
    <section class="new-features" *ngIf="!isCountdownFinished">
        
<div class="container productLeft p-0 overflow-hidden">
    <div class="">
      <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto image-description">
        <div class="row d-flex align-items-center row-direction p-3">

          <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 p-0 d-flex align-items-center flex-wrap content-feature">
            <div class="workspace-banner-content document-handling-banner-content image-content">
                <div class="launch-btn">Launching Soon</div>
              <h6 class="heading-6 text-uppercase">Automated Tally Backup</h6>
              <h1 class="heading-1">Your Tally Data Is Only as Safe as Its Backup</h1>
              <p class="common-para">Hard drives fail. Data gets deleted. Don’t let one disaster risk your entire business. Secure your business with dox2U’s automated & intelligent Tally Backup solution.</p>
              <p class="italicText">Get 30% off by signing up on our waitlist</p>
              <a routerLink="/tallybackup" target="_blank"> <button class="button-primary">Learn more</button></a>
            </div>
          </div>


          <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 p-0 d-flex justify-content-center">

            <img src={{imgSrc}} alt="document handling system" class="img-fluid launch-img">

           

          </div>
        </div>
      </div>
    </div>
  </div>

      
       </section> -->
    
    <section class="physical-cabinets top-padd common-padd cloudManagement values-offered-container">
        <div class="container px-0">

        <div class="d-flex align-items-center flex-column values-offered">
            <p class="text-blue">VALUES OFFERED</p>
            <h3 class="size-fourty text-center">Why Businesses choose dox2U’s Tally Plugin?</h3>
        </div>
        <div class="div-container">
        <div class="d-flex arrowGroup-text margin-align">
            <h4>Cost Reduction:</h4>
            <img src="../../../assets/Image/arrowGroup.svg" class="img-fluid arrow-img" loading="lazy">
            <div class="d-flex flex-column">
                <div class="d-flex ml-3 marginResp">
                    <img class="img-height img-mb" src="../../../assets/Image/noPrinting.svg" loading="lazy">
                    <div class="d-flex flex-column ml-3">
                        <p class="mb-5 values-offered-h1">No Printing & Filing:</p>
                        <p class="values-offered-h2">Slash operational costs with document storage in Tally.</p>
                    </div>
                </div>
                <div class="d-flex ml-3 marginResp">
                    <img class="img-height img-mt" src="../../../assets/Image/errorPrevention.svg" loading="lazy">
                    <div class="d-flex flex-column justify-content-end ml-3">
                        <p class="mb-5 values-offered-h1">Error Prevention:</p>
                        <p class="mb-0 values-offered-h2">Minimize expenses by streamlining document processes.</p>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div class="d-flex arrowGroup-text margin-align1">
            <h4>Time Savings:</h4>
            <img src="../../../assets/Image/arrowGroup.svg" class="img-fluid arrow-img" loading="lazy">
            <div class="d-flex flex-column">
                <div class="d-flex ml-3 marginChange moreMargin">
                    <img class="img-height img-mb" src="../../../assets/Image/effortlessAccess.svg" loading="lazy">
                    <div class="d-flex flex-column ml-3">
                        <p class="mb-5 values-offered-h1">Effortless Access:</p>
                        <p class="values-offered-h2">Retrieve documents instantly within Tally, eliminating time-consuming searches.</p>
                    </div>
                </div>
                <div class="d-flex ml-3 marginChange moreMargin">
                    <img class="img-height img-mt" src="../../../assets/Image/fasterCompliances.svg" loading="lazy">
                    <div class="d-flex flex-column justify-content-end ml-3">
                        <p class="mb-5 values-offered-h1">Faster Compliances:</p>
                        <p class="mb-0 values-offered-h2">Ensure quicker audits and compliance within set timelines.</p>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div class="d-flex arrowGroup-text margin-align2">
            <h4>Enhanced Efficiency:</h4>
            <img src="../../../assets/Image/arrowGroup.svg" class="img-fluid arrow-img" loading="lazy">
            <div class="d-flex flex-column">
                <div class="d-flex ml-3 marginChange">
                    <img class="img-height img-mb img-resp" src="../../../assets/Image/integratedWF.svg" loading="lazy">
                    <div class="d-flex flex-column ml-3 marginResp1">
                        <p class="mb-5 values-offered-h1">Integrated Workflow:</p>
                        <p class="values-offered-h2">Seamlessly integrate documents into Tally for enhanced work processes.</p>
                    </div>
                </div>
                <div class="d-flex ml-3 marginChange">
                    <img class="img-height img-mt img-resp" src="../../../assets/Image/AAA.svg" loading="lazy">
                    <div class="d-flex flex-column justify-content-end ml-3 marginResp1">
                        <p class="mb-5 values-offered-h1">Anytime, Anywhere Access:</p>
                        <p class="mb-0 values-offered-h2">Boost flexibility and productivity with access to documents anywhere, anytime.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
        </div>
      
    </section>
    <app-tally-management-plugin></app-tally-management-plugin>

    <!-- <section class="physical-cabinets top-padd common-padd cloudManagement displayChange" *ngIf="!isCountdownFinished">
        <div class="container px-0">
            <div class="row mx-0 justify-content-center">
               
                    <div class="parallex-sec-header">
                        <p class="header2 mb-0">TRANSFORM TALLY INTO A CLOUD DMS</p>
                        <h3 class="size-fourty">What’s different about dox2U’s Tally DMS Plugin?</h3>
                        <p class="common-heading text-center mb-0">Unique solution for effortless document management within Tally. 
                            Seamless integration, cloud vault security, user management, and guest sharing.</p>
                    </div>
                    <div class="parallex-sec-main" style="position: relative;">
                    <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 parallex-sec-container d-flex px-0">
                        <div class="heading heading-container col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-12 px-0" [class.heading-sticky1]="isheadingElementContainer">
                            
                            <a (click)="scrollToElement('headingElement1')"><h2 class="parallex-sec-header1" [ngClass]="{'opacity-change1': isHeadingEleminViewport1, 'opacity-normal': !isHeadingEleminViewport1}">Integration</h2></a>
                            <a (click)="scrollToElement('headingElement2')"><h2 class="parallex-sec-header1" [ngClass]="{'opacity-change1': isHeadingEleminViewport2 && !isHeadingEleminViewport1, 'opacity-normal': !isHeadingEleminViewport2}">Document Management</h2></a>
                            <a (click)="scrollToElement('headingElement3')"><h2 class="parallex-sec-header1" [ngClass]="{'opacity-change1': isHeadingEleminViewport3 && !isHeadingEleminViewport2, 'opacity-normal': !isHeadingEleminViewport3}">Cloud Vault</h2></a>
                            <a (click)="scrollToElement('headingElement4')"><h2 class="parallex-sec-header1" [ngClass]="{'opacity-change1': isHeadingEleminViewport4 && !isHeadingEleminViewport3, 'opacity-normal': !isHeadingEleminViewport4}">User Management</h2></a>
                            <a (click)="scrollToElement('headingElement5')"><h2 class="parallex-sec-header1" [ngClass]="{'opacity-change1': !isHeadingEleminViewport4}">Sharing with Guests</h2></a>
                        </div>
                        <div class="text-heading col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-12 px-0" id="headingElementContainer">
                            <div id="headingElement1" class="headingElement" [ngClass]="{'opacity-change': isHeadingEleminViewport1, 'opacity-normal1': !isHeadingEleminViewport1}"> -->
                                <!-- <div id="headingElement1" class="headingElement opacity-change"> -->
                                <!-- <img src="../../../assets/Image/IntegrationHeader.svg" data-aos="fade-up" data-aos-duration="2500" loading="lazy" class="mb-img img-fluid ">
                                        
                                <div class="d-flex flex-wrap">
                                    <div class="d-flex ">
                                        <p class="common-para text1 wunset"> <span class="horizontal-line"> </span>
                                           <span class="fw-900">Seamlessly integrate</span>&nbsp;document management within Tally for a unified user experience.
                                        </p>
                                    </div>
                    
                                    <div class="d-flex ">
                                        <p class="common-para text1 wunset"> <span class="horizontal-line"> </span>
                                            <span class="fw-900"> Automatically link documents</span>&nbsp;to Tally vouchers or masters, reducing manual effort.
                                        </p>
                                    </div>
                    
                                    <div class="d-flex ">
                                        <p class="common-para text1 wunset"> <span class="horizontal-line"> </span>
                                            Sync documents from&nbsp;<span class="fw-900">unlimited companies</span>&nbsp;to a single dox2U Workspace.
                                        </p>
                                    </div>
                    
                                    <div class="d-flex ">
                                        <p class="common-para text1 wunset"> <span class="horizontal-line"> </span>
                                            A familiar &&nbsp;<span class="fw-900">user-friendly interface</span>&nbsp;for a smooth and intuitive user experience.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        <div id="headingElement2" class="headingElement" [ngClass]="{'opacity-change': isHeadingEleminViewport2 && !isHeadingEleminViewport1, 'opacity-normal1': !isHeadingEleminViewport2}"> -->
                            <!-- <div id="headingElement2" class="headingElement opacity-change"> -->
                            <!-- <img src="../../../assets/Image/documentMangaement.svg" data-aos="fade-up" data-aos-duration="2500" loading="lazy" class="mb-img img-fluid ">
                            
                            <div class="d-flex flex-wrap">
                                <div class="d-flex ">
                                    <p class="common-para text1 wunset"> <span class="horizontal-line"> </span>
                                       <span class="fw-900">Upload files</span>&nbsp;associated with Vouchers & Masters directly from your system. Choose from a wide list of supported formats.
                                    </p>
                                </div>
                
                                <div class="d-flex ">
                                    <p class="common-para text1 wunset"> <span class="horizontal-line"> </span>
                                        Add documents directly to dox2U’s cloud using Web-App or Sync-App and find them in Tally for&nbsp;<span class="fw-900">easy linking.</span>  
                                    </p>
                                </div>
                
                                <div class="d-flex ">
                                    <p class="common-para text1 wunset"> <span class="horizontal-line"> </span>
                                        <span class="fw-900">Effortless access</span>&nbsp;to linked documents directly within Tally, eliminating the need for extensive searches.
                                    </p>
                                </div>
                
                                <div class="d-flex ">
                                    <p class="common-para text1 wunset"> <span class="horizontal-line"> </span>
                                        Find documents, even scanned images, with ICR-based&nbsp;<span class="fw-900">full-text search.</span>
            
                                    </p>
                                </div>

                                <div class="d-flex ">
                                    <p class="common-para text1 wunset"> <span class="horizontal-line"> </span>
                                        Digitally archive&nbsp;<span class="fw-900">Tally vouchers</span>&nbsp;for future reference.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div id="headingElement3" class="headingElement"  [ngClass]="{'opacity-change': isHeadingEleminViewport3 && !isHeadingEleminViewport2, 'opacity-normal1': !isHeadingEleminViewport3}">
                             <div id="headingElement3" class="headingElement opacity-change"> -->
                            <!-- <img src="../../../assets/Image/cloudVault.svg" data-aos="fade-up" data-aos-duration="2500" loading="lazy" class="mb-img img-fluid ">
                            
                            <div class="d-flex flex-wrap">
                                <div class="d-flex ">
                                    <p class="common-para text1 wunset"> <span class="horizontal-line"> </span>
                                       Ensure&nbsp;<span class="fw-900">data safety</span>&nbsp;with robust encryption in your secure cloud vault
                                    </p>
                                </div>
                
                                <div class="d-flex ">
                                    <p class="common-para text1 wunset"> <span class="horizontal-line"> </span>
                                        Access&nbsp;<span class="fw-900">documents anytime, anywhere,</span>&nbsp;promoting flexibility in workflow.
                                    </p>
                                </div>
                
                                <div class="d-flex ">
                                    <p class="common-para text1 wunset"> <span class="horizontal-line"> </span>
                                        Organize and store documents efficiently in a secure cloud&nbsp;<span class="fw-900">digital repository.</span>
                                    </p>
                                </div>
                
                                <div class="d-flex ">
                                    <p class="common-para text1 wunset"> <span class="horizontal-line"> </span>
                                        Protect against system crashes and lost file with automated cloud vault&nbsp;<span class="fw-900">backups.</span>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div id="headingElement4" class="headingElement" [ngClass]="{'opacity-change': isHeadingEleminViewport4 && !isHeadingEleminViewport3, 'opacity-normal1': !isHeadingEleminViewport4}">
                             <div id="headingElement4" class="headingElement opacity-change"> -->
                            <!-- <img src="../../../assets/Image/userManagement.svg" data-aos="fade-up" data-aos-duration="2500" loading="lazy"  class="mb-img img-fluid ">
                            
                            <div class="d-flex flex-wrap">
                                <div class="d-flex ">
                                    <p class="common-para text1 wunset"> <span class="horizontal-line"> </span>
                                       Manage&nbsp;<span class="fw-900">user rights and permissions</span>&nbsp;for comprehensive data security and controls
                                    </p>
                                </div>
                
                                <div class="d-flex ">
                                    <p class="common-para text1 wunset"> <span class="horizontal-line"> </span>
                                        Grant&nbsp;<span class="fw-900">view-only users</span>&nbsp;access to view Tally-linked documents.
                                    </p>
                                </div>
                
                                <div class="d-flex ">
                                    <p class="common-para text1 wunset"> <span class="horizontal-line"> </span>
                                        Facilitate seamless collaboration with&nbsp;<span class="fw-900">Chartered Accountants</span>&nbsp;through free access.
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div id="headingElement5" class="headingElement opacity-change">
                            <img src="../../../assets/Image/sharingWithGuests.svg" data-aos="fade-up" data-aos-duration="2500" loading="lazy"  class="mb-img img-fluid ">
                            
                            <div class="d-flex flex-wrap">
                                <div class="d-flex ">
                                    <p class="common-para text1 wunset"> <span class="horizontal-line"> </span>
                                        Easily share documents with guests via&nbsp;<span class="fw-900">WhatsApp or email</span>&nbsp;for quick collaboration.
                                    </p>
                                </div>
                
                                <div class="d-flex ">
                                    <p class="common-para text1 wunset"> <span class="horizontal-line"> </span>
                                        Securely share documents using&nbsp;<span class="fw-900">duration control,</span>&nbsp;with access automatically revoked upon expiry.
                                    </p>
                                </div>
                
                                <div class="d-flex ">
                                    <p class="common-para text1 wunset"> <span class="horizontal-line"> </span>
                                        Manage guest&nbsp;<span class="fw-900">download and print</span>&nbsp;permissions for heightened security.
                                    </p>
                                </div>

                                <div class="d-flex ">
                                    <p class="common-para text1 wunset"> <span class="horizontal-line"> </span>
                                        Enhance security during document sharing with&nbsp;<span class="fw-900">One-Time Password protection.</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                      
                    </div>
          
                </div>

                <div class="heading-container" [class.position-change]="isheadingElementContainer">
                    <a (click)="scrollToElement('headingElement1')" [class.bold-heading-headingElement1] = "isHeadingEleminViewport1"> <span class="number">01</span><h5 class="heading-elem">Integration</h5></a>
                    <a (click)="scrollToElement('headingElement2')" [class.bold-heading-headingElement2] = "isHeadingEleminViewport2"><span class="number number2">02</span><h5 class="heading-elem">Document Management</h5></a>
                    <a (click)="scrollToElement('headingElement3')" [class.bold-heading-headingElement3] = "isHeadingEleminViewport3"><span class="number number3">03</span><h5 class="heading-elem">Cloud Vault</h5></a>
                    <a (click)="scrollToElement('headingElement4')" [class.bold-heading-headingElement4] = "isHeadingEleminViewport4"><span class="number number4">04</span><h5 class="heading-elem">User Management</h5></a>
                    <a (click)="scrollToElement('headingElement5')" [class.bold-heading-headingElement5] = "isHeadingEleminViewport5"><span class="number number5">05</span><h5 class="heading-elem">Sharing with Guests</h5></a>
                </div>
                <div class="scrollElement">
                    <div class="scrollElement-container" id="headingElementContainer">
                      <div id="headingElement1" class="headingElement" [ngClass]="{'opacity-change': isHeadingEleminViewport1, 'opacity-normal1': !isHeadingEleminViewport1}"> 
                            <div id="headingElement1" class="headingElem firstElem" [ngClass]="{'squeezing': isHeadingEleminViewport2}">
                                <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 image-asset">
                           <img src="../../../assets/Image/IntegrationHeader.svg" class="mb-img img-fluid "></div>
                                    
                            <div class="d-flex flex-wrap list-container col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                <div class="list-header">
                                <h3>Integration</h3>
                            </div>
                                <div class="d-flex ">
                                    <p class="common-para text1 wunset"> <span class="horizontal-line"> </span>
                                       <span class="fw-900">Seamlessly integrate</span>&nbsp;document management within Tally for a unified user experience.
                                    </p>
                                </div>
                
                                <div class="d-flex ">
                                    <p class="common-para text1 wunset"> <span class="horizontal-line"> </span>
                                        <span class="fw-900"> Automatically link documents</span>&nbsp;to Tally vouchers or masters, reducing manual effort.
                                    </p>
                                </div>
                
                                <div class="d-flex ">
                                    <p class="common-para text1 wunset"> <span class="horizontal-line"> </span>
                                        Sync documents from&nbsp;<span class="fw-900">unlimited companies</span>&nbsp;to a single dox2U Workspace.
                                    </p>
                                </div>
                
                                <div class="d-flex ">
                                    <p class="common-para text1 wunset"> <span class="horizontal-line"> </span>
                                        A familiar &&nbsp;<span class="fw-900">user-friendly interface</span>&nbsp;for a smooth and intuitive user experience.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div id="headingElement2" class="headingElement" [ngClass]="{'opacity-change': isHeadingEleminViewport2 && !isHeadingEleminViewport1, 'opacity-normal1': !isHeadingEleminViewport2}">
                         <div id="headingElement2" class="headingElem" [ngClass]="{'squeezing': isHeadingEleminViewport3}">
                            <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 image-asset">
                         <img src="../../../assets/Image/documentMangaement.svg" class="mb-img img-fluid "> </div>
                        
                        <div class="d-flex flex-wrap list-container col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <div class="list-header">
                                <h3>Document Management</h3>
                            </div>
                            <div class="d-flex ">
                                <p class="common-para text1 wunset"> <span class="horizontal-line"> </span>
                                   <span class="fw-900">Upload files</span>&nbsp;associated with Vouchers & Masters directly from your system. Choose from a wide list of supported formats.
                                </p>
                            </div>
            
                            <div class="d-flex ">
                                <p class="common-para text1 wunset"> <span class="horizontal-line"> </span>
                                    Add documents directly to dox2U’s cloud using Web-App or Sync-App and find them in Tally for&nbsp;<span class="fw-900">easy linking.</span>  
                                </p>
                            </div>
            
                            <div class="d-flex ">
                                <p class="common-para text1 wunset"> <span class="horizontal-line"> </span>
                                    <span class="fw-900">Effortless access</span>&nbsp;to linked documents directly within Tally, eliminating the need for extensive searches.
                                </p>
                            </div>
            
                            <div class="d-flex ">
                                <p class="common-para text1 wunset"> <span class="horizontal-line"> </span>
                                    Find documents, even scanned images, with ICR-based&nbsp;<span class="fw-900">full-text search.</span>
        
                                </p>
                            </div>

                            <div class="d-flex ">
                                <p class="common-para text1 wunset"> <span class="horizontal-line"> </span>
                                    Digitally archive&nbsp;<span class="fw-900">Tally vouchers</span>&nbsp;for future reference.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div id="headingElement3" class="headingElement"  [ngClass]="{'opacity-change': isHeadingEleminViewport3 && !isHeadingEleminViewport2, 'opacity-normal1': !isHeadingEleminViewport3}">
                         <div id="headingElement3" class="headingElem" [ngClass]="{'squeezing': isHeadingEleminViewport4}">
                    <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 image-asset"> <img src="../../../assets/Image/cloudVault.svg" class="mb-img img-fluid ">
                        </div>
                        <div class="d-flex flex-wrap list-container col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <div class="list-header">
                                <h3>Cloud Vault</h3>
                            </div>
                            <div class="d-flex ">
                                <p class="common-para text1 wunset"> <span class="horizontal-line"> </span>
                                   Ensure&nbsp;<span class="fw-900">data safety</span>&nbsp;with robust encryption in your secure cloud vault
                                </p>
                            </div>
            
                            <div class="d-flex ">
                                <p class="common-para text1 wunset"> <span class="horizontal-line"> </span>
                                    Access&nbsp;<span class="fw-900">documents anytime, anywhere,</span>&nbsp;promoting flexibility in workflow.
                                </p>
                            </div>
            
                            <div class="d-flex ">
                                <p class="common-para text1 wunset"> <span class="horizontal-line"> </span>
                                    Organize and store documents efficiently in a secure cloud&nbsp;<span class="fw-900">digital repository.</span>
                                </p>
                            </div>
            
                            <div class="d-flex ">
                                <p class="common-para text1 wunset"> <span class="horizontal-line"> </span>
                                    Protect against system crashes and lost file with automated cloud vault&nbsp;<span class="fw-900">backups.</span>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div id="headingElement4" class="headingElement" [ngClass]="{'opacity-change': isHeadingEleminViewport4 && !isHeadingEleminViewport3, 'opacity-normal1': !isHeadingEleminViewport4}">
                         <div id="headingElement4" class="headingElem" [ngClass]="{'squeezing': isHeadingEleminViewport5}">
                     <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 image-asset"> <img src="../../../assets/Image/userManagement.svg" class="mb-img img-fluid ">
                       </div> 
                        <div class="d-flex flex-wrap list-container col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <div class="list-header">
                                <h3>User Management</h3>
                            </div>
                            <div class="d-flex ">
                                <p class="common-para text1 wunset"> <span class="horizontal-line"> </span>
                                   Manage&nbsp;<span class="fw-900">user rights and permissions</span>&nbsp;for comprehensive data security and controls
                                </p>
                            </div>
            
                            <div class="d-flex ">
                                <p class="common-para text1 wunset"> <span class="horizontal-line"> </span>
                                    Grant&nbsp;<span class="fw-900">view-only users</span>&nbsp;access to view Tally-linked documents.
                                </p>
                            </div>
            
                            <div class="d-flex ">
                                <p class="common-para text1 wunset"> <span class="horizontal-line"> </span>
                                    Facilitate seamless collaboration with&nbsp;<span class="fw-900">Chartered Accountants</span>&nbsp;through free access.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div id="headingElement5" class="headingElem">
                  <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12 image-asset"> <img src="../../../assets/Image/sharingWithGuests.svg" class="mb-img img-fluid ">
                        </div>
                        <div class="d-flex flex-wrap list-container col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <div class="list-header">
                                <h3>Sharing with Guests</h3>
                            </div>
                            <div class="d-flex ">
                                <p class="common-para text1 wunset"> <span class="horizontal-line"> </span>
                                    Easily share documents with guests via&nbsp;<span class="fw-900">WhatsApp or email</span>&nbsp;for quick collaboration.
                                </p>
                            </div>
            
                            <div class="d-flex ">
                                <p class="common-para text1 wunset"> <span class="horizontal-line"> </span>
                                    Securely share documents using&nbsp;<span class="fw-900">duration control,</span>&nbsp;with access automatically revoked upon expiry.
                                </p>
                            </div>
            
                            <div class="d-flex ">
                                <p class="common-para text1 wunset"> <span class="horizontal-line"> </span>
                                    Manage guest&nbsp;<span class="fw-900">download and print</span>&nbsp;permissions for heightened security.
                                </p>
                            </div>

                            <div class="d-flex ">
                                <p class="common-para text1 wunset"> <span class="horizontal-line"> </span>
                                    Enhance security during document sharing with&nbsp;<span class="fw-900">One-Time Password protection.</span>
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        </div>
        </section> -->

    <section class="physical-cabinets top-padd common-padd cloudManagement displayResp d-none">
        <div class="container">
            <div class="row justify-content-start mx-auto">
               
                    <div class="parallex-sec-header">
                        <p class="header2">TRANSFORM TALLY INTO A CLOUD DMS</p>
                        <h3 class="size-fourty">What’s different about dox2U’s Tally DMS Plugin?</h3>
                        <p class="common-heading text-left mb-0">Unique solution for effortless document management within Tally. 
                            Seamless integration, cloud vault security, user management, and guest sharing.</p>
                    </div>
        <div class="container px-0 mx-0">
            <ol class="c-timeline pl-0">
              <li class="c-timeline__item">
                <div class="c-timeline__content" id="headingElement1" >
                    <img src="../../../assets/Image/IntegrationHeader.svg" data-aos="fade-up" data-aos-duration="2500" loading="lazy"  class="mb-img img-fluid ">
                            
                    <div class="d-flex flex-wrap">
                        <div class="d-flex ">
                            <p class="common-para text1 wunset"> <span class="horizontal-line"> </span>
                               <span class="fw-900">Seamlessly integrate</span>&nbsp;document management within Tally for a unified user experience.
                            </p>
                        </div>
        
                        <div class="d-flex ">
                            <p class="common-para text1 wunset"> <span class="horizontal-line"> </span>
                                <span class="fw-900"> Automatically link documents</span>&nbsp;to Tally vouchers or masters, reducing manual effort.
                            </p>
                        </div>
        
                        <div class="d-flex ">
                            <p class="common-para text1 wunset"> <span class="horizontal-line"> </span>
                                Sync documents from&nbsp;<span class="fw-900">unlimited companies</span>&nbsp;to a single dox2U Workspace.
                            </p>
                        </div>
        
                        <div class="d-flex ">
                            <p class="common-para text1 wunset"> <span class="horizontal-line"> </span>
                                A familiar &&nbsp;<span class="fw-900">user-friendly interface</span>&nbsp;for a smooth and intuitive user experience.
                            </p>
                        </div>
                    </div>
                </div>
                <h2 class="parallex-sec-header1" [class.heading-sticky1]="isHeadingEleminViewport1">Integration</h2>
              </li>
              <li class="c-timeline__item">
                <div class="c-timeline__content" id="headingElement2" >
                    <img src="../../../assets/Image/IntegrationHeader.svg" data-aos="fade-up" data-aos-duration="2500" loading="lazy"  class="mb-img img-fluid ">
                            
                    <div class="d-flex flex-wrap">
                        <div class="d-flex ">
                            <p class="common-para text1 wunset"> <span class="horizontal-line"> </span>
                               <span class="fw-900">Upload files</span>&nbsp;associated with Vouchers & Masters directly from your system. Choose from a wide list of supported formats.
                            </p>
                        </div>
        
                        <div class="d-flex ">
                            <p class="common-para text1 wunset"> <span class="horizontal-line"> </span>
                                Add documents directly to dox2U’s cloud using Web-App or Sync-App and find them in Tally for&nbsp;<span class="fw-900">easy linking.</span>  
                            </p>
                        </div>
        
                        <div class="d-flex ">
                            <p class="common-para text1 wunset"> <span class="horizontal-line"> </span>
                                <span class="fw-900">Effortless access</span>&nbsp;to linked documents directly within Tally, eliminating the need for extensive searches.
                            </p>
                        </div>
        
                        <div class="d-flex ">
                            <p class="common-para text1 wunset"> <span class="horizontal-line"> </span>
                                Find documents, even scanned images, with ICR-based&nbsp;<span class="fw-900">full-text search.</span>
    
                            </p>
                        </div>

                        <div class="d-flex ">
                            <p class="common-para text1 wunset"> <span class="horizontal-line"> </span>
                                Digitally archive&nbsp;<span class="fw-900">Tally vouchers</span>&nbsp;for future reference.
                            </p>
                        </div>
                    </div>
                </div>
                <h2 class="parallex-sec-header1" [class.heading-sticky2]="isHeadingEleminViewport2">Document Management</h2>
              </li>
              <li class="c-timeline__item">
                <div class="c-timeline__content" id="headingElement3" >
                    <img src="../../../assets/Image/IntegrationHeader.svg" data-aos="fade-up" data-aos-duration="2500" loading="lazy" class="mb-img img-fluid ">
                            
                    <div class="d-flex flex-wrap">
                        <div class="d-flex ">
                            <p class="common-para text1 wunset"> <span class="horizontal-line"> </span>
                               Ensure&nbsp;<span class="fw-900">data safety</span>&nbsp;with robust encryption in your secure cloud vault
                            </p>
                        </div>
        
                        <div class="d-flex ">
                            <p class="common-para text1 wunset"> <span class="horizontal-line"> </span>
                                Access&nbsp;<span class="fw-900">documents anytime, anywhere,</span>&nbsp;promoting flexibility in workflow.
                            </p>
                        </div>
        
                        <div class="d-flex ">
                            <p class="common-para text1 wunset"> <span class="horizontal-line"> </span>
                                Organize and store documents efficiently in a secure cloud&nbsp;<span class="fw-900">digital repository.</span>
                            </p>
                        </div>
        
                        <div class="d-flex ">
                            <p class="common-para text1 wunset"> <span class="horizontal-line"> </span>
                                Protect against system crashes and lost file with automated cloud vault&nbsp;<span class="fw-900">backups.</span>
                            </p>
                        </div>
                    </div>
                </div>
                <h2 class="parallex-sec-header1" [class.heading-sticky3]="isHeadingEleminViewport3">Cloud Vault</h2>
              </li>
              <li class="c-timeline__item">
                <div class="c-timeline__content" id="headingElement4">
                    <img src="../../../assets/Image/IntegrationHeader.svg" data-aos="fade-up" data-aos-duration="2500" loading="lazy" class="mb-img img-fluid ">
                            
                    <div class="d-flex flex-wrap">
                        <div class="d-flex ">
                            <p class="common-para text1 wunset"> <span class="horizontal-line"> </span>
                               Manage&nbsp;<span class="fw-900">user rights and permissions</span>&nbsp;for comprehensive data security and controls
                            </p>
                        </div>
        
                        <div class="d-flex ">
                            <p class="common-para text1 wunset"> <span class="horizontal-line"> </span>
                                Grant&nbsp;<span class="fw-900">view-only users</span>&nbsp;access to view Tally-linked documents.
                            </p>
                        </div>
        
                        <div class="d-flex ">
                            <p class="common-para text1 wunset"> <span class="horizontal-line"> </span>
                                Facilitate seamless collaboration with&nbsp;<span class="fw-900">Chartered Accountants</span>&nbsp;through free access.
                            </p>
                        </div>
                    </div>
                </div>
                <h2 class="parallex-sec-header1" [class.heading-sticky4]="isHeadingEleminViewport4">User Management</h2>
              </li>
              <li class="c-timeline__item">
                <div class="c-timeline__content" id="headingElement5">
                    <img src="../../../assets/Image/IntegrationHeader.svg" data-aos="fade-up" data-aos-duration="2500" loading="lazy"  class="mb-img img-fluid ">
                            
                            <div class="d-flex flex-wrap">
                                <div class="d-flex ">
                                    <p class="common-para text1 wunset"> <span class="horizontal-line"> </span>
                                        Easily share documents with guests via&nbsp;<span class="fw-900">WhatsApp or email</span>&nbsp;for quick collaboration.
                                    </p>
                                </div>
                
                                <div class="d-flex ">
                                    <p class="common-para text1 wunset"> <span class="horizontal-line"> </span>
                                        Securely share documents using&nbsp;<span class="fw-900">duration control,</span>&nbsp;with access automatically revoked upon expiry.
                                    </p>
                                </div>
                
                                <div class="d-flex ">
                                    <p class="common-para text1 wunset"> <span class="horizontal-line"> </span>
                                        Manage guest&nbsp;<span class="fw-900">download and print</span>&nbsp;permissions for heightened security.
                                    </p>
                                </div>

                                <div class="d-flex ">
                                    <p class="common-para text1 wunset"> <span class="horizontal-line"> </span>
                                        Enhance security during document sharing with&nbsp;<span class="fw-900">One-Time Password protection.</span>
                                    </p>
                                </div>
                            </div>
                </div>
                <h2 class="parallex-sec-header1" [class.heading-sticky5]="isHeadingEleminViewport5">Sharing with Guests</h2>
              </li>
            </ol>
          </div>
          
        </div>
    </div>
    </section>
    
    <section id="tallyPrime" class="top-padd bottom-padd  tallyPrime">
        <div class="container">
            <div class="row tallyPrimeContainer">
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto tally-header-container">
                    <p class="dms-header1 margin-0">GET IN TOUCH</p>
                    <h3 class="size-fourty mb-3">Ready to get started or have a question?</h3>
                    <p class="common-heading share-info">Share your info, and our experts will reach out.</p>
                </div>
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 p-0">
                    <div class="row d-flex justify-content-between align-items-center mx-1" style="row-gap: 20px">
                        <!-- <div class="col-xxl-1 d-xl-none d-xxl-block d-lg-none d-md-none d-sm-none"></div> -->
                        <div class="tallyDMSForm col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 p-0 d-flex flex-column justify-content-center artopD">
                            <div id="tallyDMSshow" *ngIf="partnerFormShow">
                                <form [formGroup]="tallyPrimeForm" (ngSubmit)="onSubmitTallyPrime()">
                                    <div class="d-flex flex-column colmG">
                                        <div class="d-flex form-container w-100">
                                            <div class="d-flex flex-column w-50 input-container">
                                                <div class="nameInput d-flex flex-column form-group">
                                                    <label>
                                                        <p class="label-field mb-1 tunset">First Name<span class="text-danger"
                                                            >*</span>
                                                        </p>
                                                    </label>
                                                    <input class="form-control" formControlName="firstname" id="firstname" name="firstname" placeholder="Enter first name" (keypress)="resetFirstNameDivTP($event)">
                                                    
                                                        <!-- <div *ngIf="dmsForm.get('firstname')?.hasError('required') && dmsForm.get('firstname').touched" class="error">This field cannot be empty</div> -->
                                                </div>
                                                <p class="mb-0 meserr text-danger" *ngIf="firstNameValidDivTP">
                                                    This field cannot be empty
                                                  </p>
                                                  
                                                <p id="errfirstname1" class="mb-0  meserr text-danger"></p>
                                            </div>
                                            <div class="d-flex flex-column w-50 input-container">
                                                <div class="nameInput d-flex flex-column form-group">
                                                    <label>
                                                        <p class="label-field mb-1 tunset">Last Name<span class="text-danger"
                                                                >*</span></p>
                                                    </label>
                                                    <input class="form-control" formControlName="lastname" id="lastname" placeholder="Enter last name" name="lastname" (keypress)="resetLastNameDivTP($event)">
                                                </div>
                                                <p class="mb-0 meserr text-danger" *ngIf="lastNameValidDivTP">
                                                    This field cannot be empty
                                                  </p>
                                                <p id="errlastname1" class="mb-0  meserr"></p>
                                            </div>
                                        </div>
                                        <div class="d-flex form-container w-100">
    
                                            <div class="d-flex flex-column w-50 input-container">
                                                <div class="nameInput d-flex flex-column form-group">
                                                    <label>
                                                        <p class="label-field mb-1 tunset">Email Address<span
                                                                style="color:red">*</span>
                                                        </p>
                                                    </label>
                                                    <input class="form-control" formControlName="email" id="tallyemail" placeholder="Enter your email address" name="tallyemail" (keypress)="resetEmailDivTP($event)">
                                                </div>
                                                <p class="mb-0 meserr text-danger" *ngIf="emailValidDivEmptyTP">
                                                    This field cannot be empty
                                                  </p>
                                                  <p class="mb-0 meserr text-danger" *ngIf="emailValidDivInvalidTP">
                                                    Please enter valid email
                                                  </p>
                                                  
                                               
                                            </div>
                                            <div class="d-flex flex-column w-50 input-container">
                                                <div class="nameInput d-flex flex-column form-group">
                                                    <label class="phn-label">
                                                        <p class="label-field mb-0 tunset">Phone Number<span class="text-danger"
                                                            >*</span></p>
                                                    </label>

                                                    <!-- <input name="phone" type="tel" id="phoneC" 
                                                        class="getting-started-input phn-inp inp100 form-control" placeholder="0987654321" formControlName="phone" (input)="resetPhoneDivTP($event)"
                                                        
                                                        maxlength="17"> -->

                                                        <ngx-intl-tel-input class="getting-started-input phn-inp inp100 form-control" (input)="resetPhoneDivTP($event)"
                                                        [cssClass]="'custom'"
                                                      
                                                        [enableAutoCountrySelect]="true"
                                                        [enablePlaceholder]="true"
                                                        [enableAutoCountrySelect]="true"
                                                        [searchCountryFlag]="true"
                                                        [searchCountryField]="[
                                                          SearchCountryField.Iso2,
                                                          SearchCountryField.Name
                                                        ]"
                                                        [selectFirstCountry]="false"
                                                        [selectedCountryISO]="CountryISO.India"
                                                        [maxLength]="15"
                                                        [phoneValidation]="true"
                                                        [separateDialCode]="separateDialCode"
                                                        [numberFormat]="PhoneNumberFormat.National"
                                                        name="phone"
                                                        formControlName="phone"
                                                        (countryChange)="onCountryChangeTP($event)"
	></ngx-intl-tel-input>
                                                </div>
                                                <p class="mb-0 meserr text-danger" *ngIf="phoneValidDivTP">
                                                    This field cannot be empty
                                                  </p>

                                                  <p class="mb-0 meserr text-danger" *ngIf="phoneValidationCheckDivTP">
                                                    Please enter the valid number
                                                  </p>
    
                                            </div>

                                            
                                        </div>
                                        <div class="d-flex flex-column w-100 input-container">
                                            <div class="nameInput d-flex flex-column form-group">
                                                <label>
                                                    <p class="label-field mb-0 tunset">Company Name<span class="text-danger"
                                                        >*</span></p>
                                                </label>

                                                <input name="companyName" id="companyName" 
                                                    class="getting-started-input form-control" placeholder="Enter your company name" formControlName="companyname" (input)="resetCompanyNameDivTP($event)"
                                                    
                                                   >
                                            </div>
                                            <p class="mb-0 meserr text-danger" *ngIf="companyNameValidDivTP">
                                                This field cannot be empty
                                              </p>

                                        </div>
                                        <div class="justify-content-center align-items-center d-flex submit-button">
                                            <button class="button-primary trydox" id="myButton" type="submit" [disabled]="isLoading"><span
                                                    class="hideDownload">Submit</span> </button>
                                            <div id="loader" class="spinner-border text-white"
                                                style="display:none;position:absolute;" role="status">
                                                <span class="sr-only"></span>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div id="tallyDMShide" *ngIf="partnerFormSubmitted" class="d-flex  flex-column justify-content-center align-items-center"
                            style="height:363px">
                            <p class="common-para text-center">Thank you for reaching out. Someone from our team will
                                connect with you shortly! </p>
                            <img src="../../../assets/Image/tallysuccess.svg" loading="lazy">
                            </div>
<!--     
                            <i class="textbot  sales-support">For Sales & Support : <span class="textbot-number">
                                +91
                                8448857421 </span> </i> -->
    
                        </div>

                        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 tally-img">
                            <img src="../../../assets/Image/Tally prime.png" class="img-fluid" loading="lazy">
                            <div class="salesinfo-para">
                                <p>
                                    For Sales & Support : +91 8448857421
                                </p>
                            </div>
                        </div>
    
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- <app-eightcards-document></app-eightcards-document> -->

    <!-- <section id="partnerWithUs" class="partner-with-us top-padd bottom-padd common-padd overflow-hidden">
        <div class="container">
            <div class="row">
    
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto">
                    <div class="row d-flex align-items-center ">
                        <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-12 col-sm-12 d-flex justify-content-start ">
                            <div class="pw-anchor">
                                <h3 class="heading-3 ">Partner with us!</h3>
                                <p class="common-para mb-5">Become a part of dox2U’s digital revolution by joining our
                                    Channel Partner network.
                                    We offer attractive Reseller plans as well as Referral plans!</p>
                                    
                            </div>
                        </div>
                        <div class="col-xxl-1 d-xl-none d-xxl-block d-lg-none d-md-none d-sm-none"></div>
                        <div class="col-xxl-7 col-xl-7 col-lg-7 col-md-12 col-sm-12 d-flex justify-content-center partnerFormContainer martopD">
                            <div id="partnerDMshow" style="width:100%">
                                <form id="partnerUsForm" name="partnerUsForm" [formGroup]="partnerForm" (ngSubmit)="onSubmitParther()" *ngIf="partnerFormShow">
                                    <div class="d-flex flex-column colmG">
                                        <div class="d-flex gap26 w-100">
                                            <div class="d-flex flex-column w-50 input-container">
                                                <div class="nameInput d-flex flex-column">
                                                    <label>
                                                        <p class="label-field mb-1 tunset">First Name<span
                                                                style="color:red">*</span>
                                                        </p>
                                                    </label>
                                                    <input class="input-border-none form-control" id="firstnameP" formControlName="firstnameP" placeholder="Enter first name"
                                                    (keypress)="resetFirstNamePDiv($event)" (mousedown)="resetFirstNamePDiv($event)">
                                                </div>
                                                <p id="errfirstnameP" class="mb-0  meserr" *ngIf="firstNamePValidDiv">
                                                    This field cannot be empty
                                                </p>
                                            </div>
                                            <div class="d-flex flex-column w-50 input-container">
                                                <div class="nameInput d-flex flex-column">
                                                    <label>
                                                        <p class="label-field mb-1 tunset">Last Name<span
                                                                style="color:red">*</span>
                                                        </p>
                                                    </label>
                                                    <input class="input-border-none form-control" id="lastnameP" formControlName="lastnameP" placeholder="Enter last name"
                                                    (keypress)="resetLastNamePDiv($event)" (mousedown)="resetLastNamePDiv($event)">
                                                </div>
                                                <p id="errlastnameP" class="mb-0  meserr" *ngIf="lastNamePValidDiv">
                                                    This field cannot be empty
                                                </p>
                                            </div>
                                        </div>
                                        <div class="d-flex gap26 w-100">
                                            <div class="d-flex flex-column w-50 input-container">
                                                <div class="nameInput d-flex flex-column">
                                                    <label>
                                                        <p class="label-field mb-1 tunset">Organization<span
                                                                style="color:red">*</span>
                                                        </p>
                                                    </label>
                                                    <input class="input-border-none form-control" id="organizationP" formControlName="organizationP" placeholder="Enter organization name"
                                                    (keypress)="resetOrganizationPDiv($event)" (mousedown)="resetOrganizationPDiv($event)">
                                                </div>
                                                <p id="errorganizationP" class="mb-0  meserr" *ngIf="organizationPValidDiv">
                                                    Please enter organization name
                                                </p>
                                            </div>
                                            <div class="d-flex flex-column w-50 input-container">
                                                <div class="nameInput d-flex flex-column">
                                                    <label>
                                                        <p class="label-field mb-1 tunset">Email<span
                                                                style="color:red">*</span></p>
                                                    </label>
                                                    <input class="input-border-none form-control" id="tallyemailP" formControlName="tallyemailP" placeholder="Enter your email address"
                                                    (keypress)="resetTallyEmailPDiv($event)" (mousedown)="resetTallyEmailPDiv($event)">
                                                </div>
                                                <p id="errtallyemailP" class="mb-0  meserr" *ngIf="tallyemailPInvalidDiv">
                                                    Please enter valid email
                                                </p>
                                                <p id="errtallyemailP" class="mb-0  meserr" *ngIf="tallyemailPEmptyDiv">
                                                    This field cannot be empty
                                                </p>
                                            </div>
                                        </div>
                                        <div class="d-flex gap26 w-100">
                                            <div class="d-flex flex-column w-50 input-container">
                                                <div class="nameInput d-flex flex-column">
                                                    <label>
                                                        <p class="label-field mb-1 tunset">Phone No.<span
                                                                style="color:red">*</span>
                                                        </p>
                                                    </label>
                                                    
                                                        <input type="tel" id="phoneP"
                                        class=" input-border-none form-control intl-input partner-form-input inputwith100" formControlName="phone" placeholder="0987654321" (input)="resetPhoneCDiv($event)" (mousedown)="resetPhoneCDiv($event)"
                                        oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                        maxlength="17">
                                                      
                                                </div>
                                                <p id="errphoneP" class="mb-0  meserr" *ngIf="phonePValidDiv">
                                                    Please enter phone no.
                                                </p>

                                                <p class="mb-0 meserr text-danger" *ngIf="phonePValidationDiv">
                                                    Please enter the valid number
                                                  </p>
                                            </div>
                                            <div class="d-flex flex-column w-50 input-container">
                                                <div class="nameInput d-flex flex-column country-input">
                                                    <label>
                                                        <p class="label-field mb-1 tunset">Country<span
                                                                style="color:red">*</span>
                                                        </p>
                                                    </label>
                                                    <!-- <input class="input-border-none" id="Country" formControlName="countryP"
                                                    (keypress)="resetCountryPDiv($event)" (mousedown)="resetCountryPDiv($event)"> -->
    
                                                    <!-- <select class="input-border-none form-control inputwith100" formControlName="countryP"  name="Country" (keypress)="resetCountryPDiv($event)"  id='mySelect' name='country'>
                                                        <option value="" selected="">Select your Country</option>
                                                        <option class="common" id="country" selected></option>
                                                        <option value="Afghanistan">Afghanistan</option>
                                                        <option value="Albania">Albania</option>
                                                        <option value="Algeria">Algeria</option>
                                                        <option value="American Samoa">American Samoa</option>
                                                        <option value="Andorra">Andorra</option>
                                                        <option value="Angola">Angola</option>
                                                        <option value="Anguilla">Anguilla</option>
                                                        <option value="Antartica">Antarctica</option>
                                                        <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                                                        <option value="Argentina">Argentina</option>
                                                        <option value="Armenia">Armenia</option>
                                                        <option value="Aruba">Aruba</option>
                                                        <option value="Australia">Australia</option>
                                                        <option value="Austria">Austria</option>
                                                        <option value="Azerbaijan">Azerbaijan</option>
                                                        <option value="Bahamas">Bahamas</option>
                                                        <option value="Bahrain">Bahrain</option>
                                                        <option value="Bangladesh">Bangladesh</option>
                                                        <option value="Barbados">Barbados</option>
                                                        <option value="Belarus">Belarus</option>
                                                        <option value="Belgium">Belgium</option>
                                                        <option value="Belize">Belize</option>
                                                        <option value="Benin">Benin</option>
                                                        <option value="Bermuda">Bermuda</option>
                                                        <option value="Bhutan">Bhutan</option>
                                                        <option value="Bolivia">Bolivia</option>
                                                        <option value="Bosnia and Herzegowina">Bosnia and Herzegowina</option>
                                                        <option value="Botswana">Botswana</option>
                                                        <option value="Bouvet Island">Bouvet Island</option>
                                                        <option value="Brazil">Brazil</option>
                                                        <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                                                        <option value="Brunei Darussalam">Brunei Darussalam</option>
                                                        <option value="Bulgaria">Bulgaria</option>
                                                        <option value="Burkina Faso">Burkina Faso</option>
                                                        <option value="Burundi">Burundi</option>
                                                        <option value="Cambodia">Cambodia</option>
                                                        <option value="Cameroon">Cameroon</option>
                                                        <option value="Canada">Canada</option>
                                                        <option value="Cape Verde">Cape Verde</option>
                                                        <option value="Cayman Islands">Cayman Islands</option>
                                                        <option value="Central African Republic">Central African Republic</option>
                                                        <option value="Chad">Chad</option>
                                                        <option value="Chile">Chile</option>
                                                        <option value="China">China</option>
                                                        <option value="Christmas Island">Christmas Island</option>
                                                        <option value="Cocos Islands">Cocos (Keeling) Islands</option>
                                                        <option value="Colombia">Colombia</option>
                                                        <option value="Comoros">Comoros</option>
                                                        <option value="Congo">Congo</option>
                                                        <option value="Cook Islands">Cook Islands</option>
                                                        <option value="Costa Rica">Costa Rica</option>
                                                        <option value="Cota D'Ivoire">Cote d'Ivoire</option>
                                                        <option value="Croatia">Croatia (Hrvatska)</option>
                                                        <option value="Cuba">Cuba</option>
                                                        <option value="Cyprus">Cyprus</option>
                                                        <option value="Czech Republic">Czech Republic</option>
                                                        <option value="Denmark">Denmark</option>
                                                        <option value="Djibouti">Djibouti</option>
                                                        <option value="Dominica">Dominica</option>
                                                        <option value="Dominican Republic">Dominican Republic</option>
                                                        <option value="East Timor">East Timor</option>
                                                        <option value="Ecuador">Ecuador</option>
                                                        <option value="Egypt">Egypt</option>
                                                        <option value="El Salvador">El Salvador</option>
                                                        <option value="Equatorial Guinea">Equatorial Guinea</option>
                                                        <option value="Eritrea">Eritrea</option>
                                                        <option value="Estonia">Estonia</option>
                                                        <option value="Ethiopia">Ethiopia</option>
                                                        <option value="Falkland Islands">Falkland Islands (Malvinas)</option>
                                                        <option value="Faroe Islands">Faroe Islands</option>
                                                        <option value="Fiji">Fiji</option>
                                                        <option value="Finland">Finland</option>
                                                        <option value="France">France</option>
                                                        <option value="France Metropolitan">France, Metropolitan</option>
                                                        <option value="French Guiana">French Guiana</option>
                                                        <option value="French Polynesia">French Polynesia</option>
                                                        <option value="French Southern Territories">French Southern Territories</option>
                                                        <option value="Gabon">Gabon</option>
                                                        <option value="Gambia">Gambia</option>
                                                        <option value="Georgia">Georgia</option>
                                                        <option value="Germany">Germany</option>
                                                        <option value="Ghana">Ghana</option>
                                                        <option value="Gibraltar">Gibraltar</option>
                                                        <option value="Greece">Greece</option>
                                                        <option value="Greenland">Greenland</option>
                                                        <option value="Grenada">Grenada</option>
                                                        <option value="Guadeloupe">Guadeloupe</option>
                                                        <option value="Guam">Guam</option>
                                                        <option value="Guatemala">Guatemala</option>
                                                        <option value="Guinea">Guinea</option>
                                                        <option value="Guinea-Bissau">Guinea-Bissau</option>
                                                        <option value="Guyana">Guyana</option>
                                                        <option value="Haiti">Haiti</option>
                                                        <option value="Heard and McDonald Islands">Heard and Mc Donald Islands</option>
                                                        <option value="Holy See">Holy See (Vatican City State)</option>
                                                        <option value="Honduras">Honduras</option>
                                                        <option value="Hong Kong">Hong Kong</option>
                                                        <option value="Hungary">Hungary</option>
                                                        <option value="Iceland">Iceland</option>
                                                        <option value="India">India</option>
                                                        <option value="Indonesia">Indonesia</option>
                                                        <option value="Iran">Iran (Islamic Republic of)</option>
                                                        <option value="Iraq">Iraq</option>
                                                        <option value="Ireland">Ireland</option>
                                                        <option value="Israel">Israel</option>
                                                        <option value="Italy">Italy</option>
                                                        <option value="Jamaica">Jamaica</option>
                                                        <option value="Japan">Japan</option>
                                                        <option value="Jordan">Jordan</option>
                                                        <option value="Kazakhstan">Kazakhstan</option>
                                                        <option value="Kenya">Kenya</option>
                                                        <option value="Kiribati">Kiribati</option>
                                                        <option value="Democratic People's Republic of Korea">Korea</option>
                                                        <option value="Kuwait">Kuwait</option>
                                                        <option value="Kyrgyzstan">Kyrgyzstan</option>
                                                        <option value="Lao">Lao People's Democratic Republic</option>
                                                        <option value="Latvia">Latvia</option>
                                                        <option value="Lebanon">Lebanon</option>
                                                        <option value="Lesotho">Lesotho</option>
                                                        <option value="Liberia">Liberia</option>
                                                        <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                                                        <option value="Liechtenstein">Liechtenstein</option>
                                                        <option value="Lithuania">Lithuania</option>
                                                        <option value="Luxembourg">Luxembourg</option>
                                                        <option value="Macau">Macau</option>
                                                        <option class="common" value="Macedonia">Macedonia</option>
                                                        <option value="Madagascar">Madagascar</option>
                                                        <option value="Malawi">Malawi</option>
                                                        <option value="Malaysia">Malaysia</option>
                                                        <option value="Maldives">Maldives</option>
                                                        <option value="Mali">Mali</option>
                                                        <option value="Malta">Malta</option>
                                                        <option value="Marshall Islands">Marshall Islands</option>
                                                        <option value="Martinique">Martinique</option>
                                                        <option value="Mauritania">Mauritania</option>
                                                        <option value="Mauritius">Mauritius</option>
                                                        <option value="Mayotte">Mayotte</option>
                                                        <option value="Mexico">Mexico</option>
                                                        <option value="Micronesia">Micronesia, Federated States of</option>
                                                        <option value="Moldova">Moldova, Republic of</option>
                                                        <option value="Monaco">Monaco</option>
                                                        <option value="Mongolia">Mongolia</option>
                                                        <option value="Montserrat">Montserrat</option>
                                                        <option value="Morocco">Morocco</option>
                                                        <option value="Mozambique">Mozambique</option>
                                                        <option value="Myanmar">Myanmar</option>
                                                        <option value="Namibia">Namibia</option>
                                                        <option value="Nauru">Nauru</option>
                                                        <option value="Nepal">Nepal</option>
                                                        <option value="Netherlands">Netherlands</option>
                                                        <option value="Netherlands Antilles">Netherlands Antilles</option>
                                                        <option value="New Caledonia">New Caledonia</option>
                                                        <option value="New Zealand">New Zealand</option>
                                                        <option value="Nicaragua">Nicaragua</option>
                                                        <option value="Niger">Niger</option>
                                                        <option value="Nigeria">Nigeria</option>
                                                        <option value="Niue">Niue</option>
                                                        <option value="Norfolk Island">Norfolk Island</option>
                                                        <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                                                        <option value="Norway">Norway</option>
                                                        <option value="Oman">Oman</option>
                                                        <option value="Pakistan">Pakistan</option>
                                                        <option value="Palau">Palau</option>
                                                        <option value="Panama">Panama</option>
                                                        <option value="Papua New Guinea">Papua New Guinea</option>
                                                        <option value="Paraguay">Paraguay</option>
                                                        <option value="Peru">Peru</option>
                                                        <option value="Philippines">Philippines</option>
                                                        <option value="Pitcairn">Pitcairn</option>
                                                        <option value="Poland">Poland</option>
                                                        <option value="Portugal">Portugal</option>
                                                        <option value="Puerto Rico">Puerto Rico</option>
                                                        <option value="Qatar">Qatar</option>
                                                        <option value="Reunion">Reunion</option>
                                                        <option value="Romania">Romania</option>
                                                        <option value="Russia">Russian Federation</option>
                                                        <option value="Rwanda">Rwanda</option>
                                                        <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option> 
                                                        <option value="Saint LUCIA">Saint LUCIA</option>
                                                        <option value="Saint Vincent">Saint Vincent and the Grenadines</option>
                                                        <option value="Samoa">Samoa</option>
                                                        <option value="San Marino">San Marino</option>
                                                        <option value="Sao Tome and Principe">Sao Tome and Principe</option> 
                                                        <option value="Saudi Arabia">Saudi Arabia</option>
                                                        <option value="Senegal">Senegal</option>
                                                        <option value="Seychelles">Seychelles</option>
                                                        <option value="Sierra">Sierra Leone</option>
                                                        <option value="Singapore">Singapore</option>
                                                        <option value="Slovakia">Slovakia (Slovak Republic)</option>
                                                        <option value="Slovenia">Slovenia</option>
                                                        <option value="Solomon Islands">Solomon Islands</option>
                                                        <option value="Somalia">Somalia</option>
                                                        <option value="South Africa">South Africa</option>
                                                        <option value="South Georgia">South Georgia</option>
                                                        <option value="Span">Spain</option>
                                                        <option value="SriLanka">Sri Lanka</option>
                                                        <option value="St. Helena">St. Helena</option>
                                                        <option value="St. Pierre and Miguelon">St. Pierre and Miquelon</option>
                                                        <option value="Sudan">Sudan</option>
                                                        <option value="Suriname">Suriname</option>
                                                        <option value="Svalbard">Svalbard and Jan Mayen Islands</option>
                                                        <option value="Swaziland">Swaziland</option>
                                                        <option value="Sweden">Sweden</option>
                                                        <option value="Switzerland">Switzerland</option>
                                                        <option value="Syria">Syrian Arab Republic</option>
                                                        <option value="Taiwan">Taiwan, Province of China</option>
                                                        <option value="Tajikistan">Tajikistan</option>
                                                        <option value="Tanzania">Tanzania, United Republic of</option>
                                                        <option value="Thailand">Thailand</option>
                                                        <option value="Togo">Togo</option>
                                                        <option value="Tokelau">Tokelau</option>
                                                        <option value="Tonga">Tonga</option>
                                                        <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                                        <option value="Tunisia">Tunisia</option>
                                                        <option value="Turkey">Turkey</option>
                                                        <option value="Turkmenistan">Turkmenistan</option>
                                                        <option value="Turks and Caicos">Turks and Caicos Islands</option>
                                                        <option value="Tuvalu">Tuvalu</option>
                                                        <option value="Uganda">Uganda</option>
                                                        <option value="Ukraine">Ukraine</option>
                                                        <option value="United Arab Emirates">United Arab Emirates</option>
                                                        <option value="United Kingdom">United Kingdom</option>
                                                        <option value="United States">United States</option>
                                                        <option value="Uruguay">Uruguay</option>
                                                        <option value="Uzbekistan">Uzbekistan</option>
                                                        <option value="Vanuatu">Vanuatu</option>
                                                        <option value="Venezuela">Venezuela</option>
                                                        <option value="Vietnam">Viet Nam</option>
                                                        <option value="Virgin Islands (British)">Virgin Islands (British)</option>
                                                        <option value="Virgin Islands (U.S)">Virgin Islands (U.S.)</option>
                                                        <option value="Wallis and Futana Islands">Wallis and Futuna Islands</option>
                                                        <option value="Western Sahara">Western Sahara</option>
                                                        <option value="Yemen">Yemen</option>
                                                        <option value="Serbia">Serbia</option>
                                                        <option value="Zambia">Zambia</option>
                                                        <option value="Zimbabwe">Zimbabwe</option>
                                                      </select>
                                                </div>
                                                <p id="errcontryp" class="mb-0  meserr" *ngIf="countryPValidDiv">
                                                    Please enter country name
                                                </p>
                                            </div>
                                        </div>
                                       
                                        <div class="justify-content-center align-items-center d-flex mt-3">
                                            <button class="button-primary trydox" type="submit" id="myButton2">I am Intrested!</button>
                                            
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div id="partnerDMShide" class="d-flex  flex-column justify-content-center align-items-center" *ngIf="partnerFormSubmitted"
                                style="height:363px">
                                <p class="common-para text-center">Thank you for reaching out. Someone from our team will
                                    connect with you shortly! </p>
                                <img src="../../../assets/Image/tallysuccess.svg">
                            </div>
    
    
                        </div>
    
                    </div>
                </div>
            </div>
        </div>
    </section>  -->
    
    <section id="secureData" class="secure-data top-padd bottom-padd common-padd overflow-hidden">
        <div class="container">
            <div class="row">
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto">
                    <div class="knox-data">
                        <h6 class="text-uppercase">Fort Knox for Your Data</h6>
                        <h3 class="size-fourty mb-3">Your Data is 100% Secure</h3>
                        <p class="common-heading share-info text-center">Our commitment to safeguarding your information includes a robust multi-layer security system, featuring a stringent "for your eyes only" policy</p>
                        </div>
                        <div class="row col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto d-flex align-items-center justify-content-between">
                        <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-12 p-0">

                            <div class="d-flex ">
                                <p class="common-para text1 wunset"> <span class="horizontal-line"> </span> Advanced Encryption (both in transit and at rest)</p>
                            </div>
                            <div class="d-flex ">
                                <p class="common-para text1 wunset"> <span class="horizontal-line"> </span> Individual Customer Encryption Keys</p>
                            </div>
                            <div class="d-flex ">
                                <p class="common-para text1 wunset"> <span class="horizontal-line"> </span> Hosting on Microsoft Azure compliant with SOC II standards</p>
                            </div>
                            <div class="d-flex ">
                                <p class="common-para text1 wunset"> <span class="horizontal-line"> </span> Real-time backups across multiple zones</p>
                            </div>
                                 
                        </div>

                        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12" style="text-align: end;">
                            <img src="../../../assets/Image/secureData.svg" class="img-fluid" loading="lazy">

                        </div>
                    </div>
                  
                </div>
            </div>
        </div>
    </section>
    <section class="turbo-learn" *ngIf="isCountdownFinished">
        <div class="container special containerOverwrite p-0">
            <div class=" w-100">
                <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mx-auto p-0">
                    <div class="workspace-banner-content document-handling-banner-content text-center">
                        <h6 class="heading-6 text-uppercase">Automated Tally Backup</h6>
                        <h1 class="heading-1">Your Tally Data Is Only as Safe as Its Backup</h1>
                        
                        <p class="common-para mb-0">Hard drives fail. Data gets deleted. Don’t let one disaster risk your entire business.</p>         
                        <p class="common-para mb-0"> Secure your business with dox2U’s automated & intelligent Tally backup solution.</p>      
                        <a routerLink="/tallybackup"> <button class="button-transparent bg-white">Learn more <img src="../../../assets/Image/rightArrow_blue.svg" style="margin-left: 13px;" loading="lazy"></button></a>   
                      </div>
                    </div>
                </div>
                </div>
                
    </section>
    <section class="feature-comparision common-padd  bottmp">
        <div class="container">
            <div class="row ">
                <div class="col-sm-12 col-md-12 col-lg-10 col-xl-10 col-xxl-10 mx-auto">
                    <div class="row">
                        <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                            <div class="feature-comparision-top d-flex flex-column align-items-center mb-5 pb-3">
                                <h2 class="mb-3"><b>Frequently asked questions</b></h2>
                            </div>
                            <div class="feature-comparision-Acordian">
                                <div id="accordion" class="accordion">
                                <div class="acc-section">
                                    <div class="feature-comparision-Acordian-Item d-flex justify-content-between collapsed" data-toggle="collapse" href="#collapseOne">
                                        <h4 class="accordian-headings mb-0" style="color: rgb(88, 88, 88);">What is a Document Management System? </h4>
                                        <img src="../../../assets/Image/plus.svg" class="plus-img" loading="lazy">
                                        <img src="../../../assets/Image/minus.svg" class="minus-img" loading="lazy">
                                    </div>
                                    <div id="collapseOne" class="feature-comparision-Acordian-Body collapse" data-parent="#accordion" >
                                        <p>A document management system (DMS) is a software solution that enables organizations to store, organize, manage, and track their digital documents and files in a centralized and secure manner. It provides a structured approach to document management, allowing users to create, capture, store, retrieve, and collaborate on documents efficiently.
                                        </p>
                                    </div>
                                </div>
    
                                <div class="acc-section">
                                    <div class="feature-comparision-Acordian-Item d-flex justify-content-between collapsed" data-toggle="collapse" href="#collapseTwo">
                                        <h4 class="accordian-headings mb-0">What is the purpose of the dox2U Tally Plugin?</h4>
                                        <img src="../../../assets/Image/plus.svg" class="plus-img" loading="lazy">
                                       <img src="../../../assets/Image/minus.svg" class="minus-img" loading="lazy">
                                    </div>
                                    <div id="collapseTwo" class="feature-comparision-Acordian-Body collapse" data-parent="#accordion">
                                        <p>dox2U’s Tally Plugin is designed to enhance the functionality of Tally accounting software by providing additional features for Document Management and integrates Tally with our award-winning cloud based Document Management System. 
                                        </p>
    
                                    </div>
                                </div>
    
                                <div class="acc-section">
                                    <div class="feature-comparision-Acordian-Item d-flex justify-content-between collapsed" data-toggle="collapse" href="#collapseThree">
                                        <h4 class="accordian-headings mb-0">How does dox2U’s Tally DMS Plugin integrate with Tally accounting software?</h4>
                                        <img src="../../../assets/Image/plus.svg" class="plus-img" loading="lazy">
                                        <img src="../../../assets/Image/minus.svg" class="minus-img" loading="lazy">
                                    </div>
                                    <div id="collapseThree" class="feature-comparision-Acordian-Body collapse" data-parent="#accordion">
                                        <p>dox2U’s Tally Plugin integrates seamlessly with Tally accounting software through a simple installation process. Once installed, it adds features and functionalities to directly attach & view documents within the Tally interface..</p>
                                    </div>
                                </div>
    
                                <div class="acc-section">
                                    <div class="feature-comparision-Acordian-Item d-flex justify-content-between collapsed" data-toggle="collapse" href="#collapseFour">
                                        <h4 class="accordian-headings mb-0">What features and functionalities does dox2U’s Tally cloud DMS offer?
                                        </h4>
                                        <img src="../../../assets/Image/plus.svg" class="plus-img" loading="lazy">
                                        <img src="../../../assets/Image/minus.svg" class="minus-img" loading="lazy">
                                    </div>
                                    <div id="collapseFour" class="feature-comparision-Acordian-Body collapse" data-parent="#accordion">
                                        <p>dox2U’s Tally Plugin offers a range of features, such as attaching documents from vouchers & masters, one-click viewing of documents from Tally, sharing & collaborating on documents, etc.. It streamlines document processes, improves efficiency, and provides valuable insights for better decision-making.
                                        </p>
    
                                    </div>
                                </div>
    
                                <div class="acc-section">
                                    <div class="feature-comparision-Acordian-Item d-flex justify-content-between collapsed" data-toggle="collapse" href="#collapseFive">
                                        <h4 class="accordian-headings mb-0">Is dox2U Tally cloud DMS compatible with the latest version of Tally?
                                        </h4>
                                        <img src="../../../assets/Image/plus.svg" class="plus-img" loading="lazy">
                                        <img src="../../../assets/Image/minus.svg" class="minus-img" loading="lazy">
                                    </div>
                                    <div id="collapseFive" class="feature-comparision-Acordian-Body collapse" data-parent="#accordion">
                                        <p>Yes, dox2U’s Tally Plugin is regularly updated to ensure compatibility with the latest version of Tally accounting software and supports Tally ERP 9, Tally Prime (Release 3 as well) and also can be deployed with Tally on Cloud. It is designed to work seamlessly with Tally's most up-to-date features and functionalities.
    
                                        </p>
                                 
    
                              
            
                                    </div>
                                </div>
                                <div class="acc-section">
                                    <div class="feature-comparision-Acordian-Item d-flex justify-content-between collapse" data-toggle="collapse" href="#collapseSix">
                                        <h4 class="accordian-headings mb-0">Does dox2U’s Tally Plugin support both Tally's desktop and online versions?
                                        </h4>
                                        <img src="../../../assets/Image/plus.svg" class="plus-img" loading="lazy">
                                        <img src="../../../assets/Image/minus.svg" class="minus-img" loading="lazy">
                                    </div>
                                    <div id="collapseSix" class="feature-comparision-Acordian-Body collapse" data-parent="#accordion">
                                        <p>Yes, dox2U’s Tally Plugin supports both Tally's desktop (Silver & Gold) and online versions. It can be used with Tally installed on a local system or accessed through the cloud-based Tally.
    
                                        </p>
                                 
    
                              
            
                                    </div>
                                </div>
                                <div class="acc-section">
                                    <div class="feature-comparision-Acordian-Item d-flex justify-content-between collapsed" data-toggle="collapse" href="#collapseSeven">
                                        <h4 class="accordian-headings mb-0">How easy is it to install and configure dox2U Tally DMS Software?
                                        </h4>
                                        <img src="../../../assets/Image/plus.svg" class="plus-img" loading="lazy">
                                        <img src="../../../assets/Image/minus.svg" class="minus-img" loading="lazy">
                                    </div>
                                    <div id="collapseSeven" class="feature-comparision-Acordian-Body collapse" data-parent="#accordion">
                                        <p>The installation and configuration process of dox2U’s Tally Plugin is user-friendly and straightforward. It typically involves downloading and installing the plugin, followed by a simple setup and activation process within Tally accounting software.
    
                                        </p>
                                 
    
                              
            
                                    </div>
                                </div>
                                <div class="acc-section">
                                    <div class="feature-comparision-Acordian-Item d-flex justify-content-between collapsed" data-toggle="collapse" href="#collapseEight">
                                        <h4 class="accordian-headings mb-0">Are there any additional costs associated with using dox2U Tally Plugin?
                                        </h4>
                                        <img src="../../../assets/Image/plus.svg" class="plus-img" loading="lazy">
                                        <img src="../../../assets/Image/minus.svg" class="minus-img" loading="lazy">
                                    </div>
                                    <div id="collapseEight" class="feature-comparision-Acordian-Body collapse" data-parent="#accordion">
                                        <p>No, all you need is to install the Plugin and subscribe to one of our subscription plans depending on your use case.
    
                                        </p>
                                 
    
                              
            
                                    </div>
                                </div>
                                <div class="acc-section">
                                    <div class="feature-comparision-Acordian-Item d-flex justify-content-between collapsed" data-toggle="collapse" href="#collapseNine">
                                        <h4 class="accordian-headings mb-0">What kind of support or documentation is available for dox2U’s Tally Plugin?
                                        </h4>
                                        <img src="../../../assets/Image/plus.svg" class="plus-img" loading="lazy">
                                        <img src="../../../assets/Image/minus.svg" class="minus-img" loading="lazy">
                                    </div>
                                    <div id="collapseNine" class="feature-comparision-Acordian-Body collapse" data-parent="#accordion">
                                        <p>dox2U typically provides comprehensive documentation, including installation guides, user manuals, FAQs, and troubleshooting resources. Additionally, support may be available through email, online chat, or a dedicated support portal for any queries or technical assistance needed.
    
                                        </p>
                                 
    
                              
            
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                   
                </div>
                <div class="row mx-auto"style="margin-top: 90px">
                    <p class="itelictext mb-0" id="check1"><i>Tally is a trademark of its respective owner and is not
                            affiliated, endorsed, connected or sponsored in any way to this website, mobile application or
                            any
                            of our affiliate sites. The same is used in accordance with honest practices and not used with
                            any
                            intention to misguide customers to take unfair advantage of the trademark's distinct character
                            or
                            harm the holder's reputation.</i>.</p>
                </div>
            </div>
        </div>
    </section>
    <app-countdown-timer class="d-none" (countdownEnded)="onCountdownEnd($event)"></app-countdown-timer>
    
<script src="https://www.youtube.com/iframe_api"></script>
    
    
    
    