import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { imgContent_security, security } from 'src/constants/product-content';
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { SEOService } from 'src/app/seo.service';

@Component({
  selector: 'app-security-and-reliability',
  templateUrl: './security-and-reliability.component.html',
  styleUrls: ['./security-and-reliability.component.scss']
})
export class SecurityAndReliabilityComponent implements OnInit {
  readonly productSubmenu: typeof security =  security;
  readonly auditLogs: typeof imgContent_security =  imgContent_security;

security_banner:string="../../../../assets/Image/document-handling/dox2u_secure_banner.svg"
security_audit:string="../../../../assets/Image/security-reliability/dox2u_secure_audit.svg"

constructor(private titleService: Title, private seoService: SEOService, private renderer2: Renderer2, @Inject(DOCUMENT) private _document: Document){
    /*
      * Cannonical tag
     */
   
}
  ngOnInit(): void {
   // this.meta.addTag({rel: 'canonical', content: 'https://www.dox2u.com/security'});
   this.createLinkForCanonicalURL(); /** to add canonical tags */
    let scriptBreadcrumbListSecure = this.renderer2.createElement('script');
    scriptBreadcrumbListSecure.type = `application/ld+json`;
    scriptBreadcrumbListSecure.text = `{
      "@context": "https://schema.org/", 
      "@type": "BreadcrumbList", 
      "itemListElement": [{
        "@type": "ListItem", 
        "position": 1, 
        "name": "Home page",
        "item": "https://dox2u.com/"  
      },{
        "@type": "ListItem", 
        "position": 2, 
        "name": "Security & Reliability page",
        "item": "https://www.dox2u.com/security"  
      }]
      }`
    
    this.renderer2.appendChild(this._document.body, scriptBreadcrumbListSecure);
    this.titleService.setTitle('Secure Document Management System Software | dox2U","Powered by AES-256 encryption, dox2U is simply the most secure cloud Document Management System and Portal online. Check it out today.'); /** For Page Title */
  }

  /**
   * Method to add cannomical tags dynamically
   */
  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  }

}
