import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { buildforEveryone } from 'src/constants/product-content';
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { SEOService } from 'src/app/seo.service';

@Component({
  selector: 'app-built-for-everyone',
  templateUrl: './built-for-everyone.component.html',
  styleUrls: ['./built-for-everyone.component.scss']
})
export class BuiltForEveryoneComponent implements OnInit {
  readonly buildforEveryone: typeof buildforEveryone  =  buildforEveryone;

  build_banner_img:string="../../../../assets/Image/make-assesible/banner-img.png"

  constructor(private titleService: Title, private seoService : SEOService, private renderer2: Renderer2, @Inject(DOCUMENT) private _document: Document) {
    /*
      * Cannonical tag
     */
   
  }
  ngOnInit(): void {
   // this.meta.addTag({rel: 'canonical', content: 'https://www.dox2u.com/built-for-everyone'});
   this.createLinkForCanonicalURL(); /** to add canonical tags */
    let scriptBreadcrumb2 = this.renderer2.createElement('script');
    scriptBreadcrumb2.type = `application/ld+json`;
    scriptBreadcrumb2.text = `{
      "@context": "https://schema.org/", 
    "@type": "BreadcrumbList", 
    "itemListElement": [{
      "@type": "ListItem", 
      "position": 1, 
      "name": "Home page",
      "item": "https://dox2u.com/"  
    },{
      "@type": "ListItem", 
      "position": 2, 
      "name": "What makes us accessible page",
      "item": "https://www.dox2u.com/built-for-everyone"  
    }]
      }`
    
    this.renderer2.appendChild(this._document.body, scriptBreadcrumb2);
    this.titleService.setTitle('Affordable Document Management System | dox2U","dox2U is an industry agnostic Affordable and Low Cost Document Management System (DMS) for all your business needs.')/** For Page Title */
  }

  /**
   * Method to add cannomical tags dynamically
   */
  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  }
}
