import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { dynamic_structure, improvedCabinetText, smartCabinet, tags_filter, workLike } from 'src/constants/product-content';
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { SEOService } from 'src/app/seo.service';

@Component({
  selector: 'app-smart-cabinet',
  templateUrl: './smart-cabinet.component.html',
  styleUrls: ['./smart-cabinet.component.scss']
})
export class SmartCabinetComponent implements OnInit {
  ngOnInit(): void {
    //this.meta.addTag({rel: 'canonical', content: 'https://www.dox2u.com/smart-cabinet'});
    this.createLinkForCanonicalURL() /** to add canonical tags */
    let scriptBreadcrumbListCabinet = this.renderer2.createElement('script');
    scriptBreadcrumbListCabinet.type = `application/ld+json`;
    scriptBreadcrumbListCabinet.text = `{
      "@context": "https://schema.org/", 
    "@type": "BreadcrumbList", 
    "itemListElement": [{
      "@type": "ListItem", 
      "position": 1, 
      "name": "Home page",
      "item": "https://dox2u.com/"  
    },{
      "@type": "ListItem", 
      "position": 2, 
      "name": "Smart Cabinet page",
      "item": "https://www.dox2u.com/smart-cabinet"  
    }]
      }`
    
    this.renderer2.appendChild(this._document.body, scriptBreadcrumbListCabinet);

    this.titleService.setTitle('Smart Cabinet - File Cabinet - Document Management Software | dox2U","This Virtual File Cabinet Document Management Software System provided by dox2U, lets the users to search and retrieve documents conveniently.'); /** For Page Title */
  }

  constructor(private titleService: Title, private seoService: SEOService, private renderer2: Renderer2, @Inject(DOCUMENT) private _document: Document){
     /*
      * Cannonical tag
     */
        
  }

  readonly smartcabinetHero: typeof smartCabinet  =  smartCabinet;
  readonly smartTags_filter: typeof tags_filter  = tags_filter ;
  readonly Dynamic: typeof dynamic_structure =  dynamic_structure;
  readonly smartLike: typeof workLike =  workLike;
  readonly improvedCabinetText: typeof improvedCabinetText =  improvedCabinetText;
  improvedCabinetImg: string="../../../../assets/Image/smart-cabinet/improved_cabinet.svg ";
  smartcabinetHero_img:string="../../../../assets/Image/smart-cabinet/dox2u_cabinet_banner.svg";
  tags_filter_img:string="../../../../assets/Image/smart-cabinet/dox2u_cabinet_filters.svg";
  dynamic_img:string="../../../../assets/Image/smart-cabinet/dox2u_cabinet_smart.svg";
  cabinetPhysical_img:string="../../../../assets/Image/smart-cabinet/dox2u_cabinet_physical.svg";
  videoURL: string = '';

  /**
   * Method to add cannomical tags dynamically
   */
  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  }
}
