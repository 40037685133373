<section class="home-banner overflow-hidden">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xxl-10 col-xl-10 col-lg-12 col-md-12 col-sm-12 mx-auto">

                <div class="row d-flex align-items-center">
                    <!-- <div class="col-xxl-1"></div> -->
                    <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-12 col-sm-12 d-flex justify-content-center hero-container">
                        
                        <div class="home-banner-content">
                            <h6 class="heading-6 tallyfont">{{contentH.heading}}</h6>
                            <h1 class="heading-1">{{contentH.textStart}} <span class="underline-highlight">{{contentH.underline}}</span>{{contentH.textEnd}}</h1>
                            <p class="common-para">{{contentH.text}}</p>
                            
                     

                            <div class="d-flex home-banner-btns">
                                <a><button class="button-primary trydox cursor-pointer" (click)="scrollToComponentElement()">Try
                                        dox2U</button></a> &nbsp;
                                <a href="https://calendly.com/dox2u-dms/dox2u-demo" target="_blank" class="underlineNone"><button
                                        class="button-transparent btn-schedule d-flex align-items-center"><img src="../../../assets/Image/sche.svg"
                                            alt="" loading="lazy"> Schedule a demo</button></a>
                            </div>
                        </div>
                    </div>

                    <div class="col-xxl-7 col-xl-7 col-lg-7 col-md-12 col-sm-12 ">
                        <div class="home-banner-hero-img d-flex " data-aos="fade-left" data-aos-duration="2500">
                            <img src={{imgSrc}} alt="document management system" class="img-fluid" loading="lazy"
                               >
                        </div>
                    </div>
                    <!-- <div class="col-xxl-1"></div> -->
                </div>

            </div>
        </div>
    </div>
</section>
