<section class="relationship-banner overflow-hidden">
    <div class="container">
      <div class="row">
        <div class="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 mx-auto">
          <div class="relationship-banner-content">
            <p class="common-para text-center">Releationships we are proud of:</p>
            <ul class="d-flex m-0 p-0 align-items-center res576">
              <li class="d-flex justify-content-xl-start justify-content-md-end justify-content-sm-end"><img src="../../../assets/Image/relationships/microsoft_for_startups.svg" alt="microsoft startup" loading="lazy"></li>
              <li class="d-flex justify-content-start"><span class="d-xxl-none d-xl-block d-md-block d-sm-block">&nbsp;&nbsp; &nbsp; &nbsp;</span><img src="../../../assets/Image/relationships/Collabora_Online.svg" alt="collabora online " loading="lazy"></li>
            </ul>
          </div>
         
        </div>
      </div>
    </div>  
  </section>
  <div class="gradient-divider"></div>
  
