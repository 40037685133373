import { NgxLoggerLevel } from 'ngx-logger';
export const environment = {
  production: true,
  baseUrl: 'https://app.dox2u.com:82',
  baseUrlWS: 'https://app.dox2u.com:82',
  baseUrlWSIcon: 'https://app.dox2u.com:96',
  baseURL97: 'https://app.dox2u.com:81',
  baseOrigin: "https://dox2u.com",
  prepaid:"https://payments.dox2u.com:86",
  baseapp: "https://app.dox2u.com",
  httpUrl : "https://",
  domain : "dox2u.com",
  logLevel: NgxLoggerLevel.OFF,
  serverLogLevel: NgxLoggerLevel.ERROR,
  enableSourceMaps: false,
  elasticdbaseurl : 'https://app.dox2u.com:96',
  paymentBaseUrl: 'https://app.dox2u.com:85',
  hostName: 'app.dox2u.com',
  port: '82',
  username: '4f449975d0f68f4c29a3',
  password: '4c5106b6b2f2fa81eb5e',
  apiKey: 'Bearer af96c4f4ca6f219c44f1f6c974794d50',
  DownloadTallyMessageID: '73',
  PartnerFormsMessageID: '92',
  ContactUsFormMessageID: '75',
  ConsultationFormMessageID: '76',
  BlogNewsletterMessageID: '77',
  EbookDownloadMessageID: '78',
  CreateWSMessageID: '79',
  spid: 47

};
