import { Component, Input } from '@angular/core';
import { contentH } from 'src/app/interfaces/interfaces';

@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss']
})
export class HeroComponent {


  ngOnInit() {
  }
  

  @Input() imgSrc:string=" ";
  @Input() contentH!:contentH;

  scrollToComponentElement() {
    var windowURL = window.location.href.split('/')[3];
    console.log("URL1", windowURL);
    if (windowURL === 'tally') {
      this.scrollToDownloadTallyForm();
    } else if (windowURL === '') {
      this.scrollToGettingStarted();
    } else if (windowURL === 'Tally-DMS-Add-on') {
      this.scrollToDownloadTallyForm();
    } 
  }

  scrollToDownloadTallyForm() {
    let windowURL = window.location.href.split('/')[3];
    const downloadFormSection = document.getElementById('downloadForm');
    if (downloadFormSection) {
      const viewportHeight = window.innerHeight;
        const elementHeight = downloadFormSection.clientHeight;
        const yOffset = (viewportHeight - elementHeight) / 2;
        const scrollOptions: ScrollToOptions = {
        top: downloadFormSection.offsetTop - yOffset,
        behavior: 'smooth',
      };
        window.scrollTo(scrollOptions);
      //downloadFormSection.scrollIntoView({ behavior: 'smooth' });
    }
    return;
  }

  scrollToGettingStarted() {
    const gettingStartedElement = document.getElementById('gettingStarted');
    if (gettingStartedElement) {
      const viewportHeight = window.innerHeight;
          const elementHeight = gettingStartedElement.clientHeight;
          const yOffset = (viewportHeight - elementHeight) / 2;
          const scrollOptions: ScrollToOptions = {
          top: gettingStartedElement.offsetTop - yOffset,
          behavior: 'smooth',
        };
          window.scrollTo(scrollOptions);
      //gettingStartedElement.scrollIntoView({ behavior: 'smooth' });
    }
    return;
  }
}
