import { Component, HostListener, Inject, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { pricingCardB, pricingCardBP, pricingCardBS, pricingCardT } from 'src/constants/home-content';
import * as AOS from 'aos';
import { GeoLocationService } from 'src/app/geo-location.service';
import { Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { SEOService } from 'src/app/seo.service';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit  {
  dropdownItems: string[] = ['Annually', 'Every 2 Year', 'Every 3 Year'];
  selectedItem: number = 0;
  selectedItemResp: string = 'Annually'
  showAnnualData: boolean = true;
  showTwoYearsData: boolean = false;
  showThreeYearsData: boolean = false;
  showMore: boolean = false;
  changeOpacity: boolean = false;
  showStorageInfo: boolean = false;
  showAdditionalStorageInfo: boolean = false;
  showPaymentInfo: boolean = false;
  showDiscountInfo: boolean = false;
  showTrainingInfo: boolean = false;
  country: string = '';
  showIndiaPrice: boolean = true;
  showDollarPrice: boolean = false;
  ipAddress: any;
  deviceInfo!: DeviceInfo;
  selectedCountry: any;
  isLaunchTime: boolean = false;
  constructor(private geoLocationService: GeoLocationService, @Inject(PLATFORM_ID) private platformId: any, private seoService: SEOService, private renderer2: Renderer2, @Inject(DOCUMENT) private _document: Document, private deviceDetectorService: DeviceDetectorService, private titleService: Title, public http: HttpClient,) {
    /*
      * Cannonical tag
     */
  
  }

  toggleShowMore() {
    this.showMore = !this.showMore;
    this.changeOpacity = !this.changeOpacity;

  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event): void {
    let subheaders = document.querySelectorAll('.subheader');
    
    subheaders.forEach(subheader => {
      // Find the closest tr element that contains the subheader
      const closestTr = subheader.closest('tr');
      
      if (closestTr) {
        const nextSection = closestTr.nextElementSibling as HTMLElement;
        
        // Check if nextElementSibling is valid and is an HTMLElement
        if (nextSection) {
          const rect = nextSection.getBoundingClientRect();

          if (rect.top <= 300) {
            subheader.classList.add('sticky');
          } else {
            subheader.classList.remove('sticky');
          }
        }
      }
    });
  }

  dataCardBS = pricingCardBS;
  datCardB = pricingCardB;
  dataCardBP = pricingCardBP;
  dataCardT = pricingCardT;

  ngOnInit(): void {
    //this.meta.addTag({rel: 'canonical', content: 'https://www.dox2u.com/pricing'});
    this.createLinkForCanonicalURL(); /** to add canonical tags */
    let scriptFAQPage = this.renderer2.createElement('script');
    scriptFAQPage.type = `application/ld+json`;
    scriptFAQPage.text = `{
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": [{
          "@type": "Question",
          "name": "How much data can 50 GB hold?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "The average user stores a mix of different formats and types of documented information. 50 GB gives you the option of storing roughly:
      100,000 pages of A4 sized scanned documents (appx 500 KB per page)
      More than 200,000 workable document files (docx, doc, txt, etc..)"
          }
        },{
          "@type": "Question",
          "name": "What if I need additional storage?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "If you are on our limited storage plans, you can either free up storage by deleting the documents that you do not use OR add more users to your subscription OR you can choose to upgrade your plan (with unlimited storage plans available) as per your need."
          }
        },{
          "@type": "Question",
          "name": "What are dCoins?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "dCoins are used by us to facilitate you for using some of our pay-per-use services. Such pay-per-use services are prepaid and you will need dCoins in your Wallet with us to use these services. You can choose the right-fit pack depending on your usage. Currently, we offer WhatsApp messaging as part of such services, which will soon be expanded to include other cognitive features."
          }
        },{
          "@type": "Question",
          "name": "What are the accepted payment methods?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Payments to dox2U can be made using most major Debit and Credit Cards like Visa, MasterCard or AMEX. We will soon be adding other options for accepting digital payments online. Meanwhile, if you prefer using other modes of digital payments, you could choose the Offline Payment method while managing your subscriptions."
          }
        },{
          "@type": "Question",
          "name": "Are there any discounts available for start-ups, educational institutions, and NGOs?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "At dox2U, we support start-ups and organizations that are striving to bring about positive change. Please contact dox2U’s sales team for further information on the availability of discounts."
          }
        },{
          "@type": "Question",
          "name": "How do you provide training and support on using dox2U?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "dox2U is an amazingly intuitive system to work with. A close associate says “If you know what you want to do, then you don’t need to think how to do it in dox2U.”
      
      Further on, there is a ready online support that works round the clock and assists the users in the midst of any task. Clicking on the ‘Live Help’ brings up trained associate already on the same page knowing where you are, and saving you the time and trouble to explain. The expert staff assists you in real-time and takes you through the task just short of actually doing it for you.
      
      Meanwhile, more videos are getting added to help you manage configurations and for other operational controls"
          }
        },{
          "@type": "Question",
          "name": "How does dox2U's billing work?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "dox2U's pricing consists of two components - plan charges and add-on users.
      
      Each Plan includes 1 user by default. In case your subscription plan supports the addition
      of multiple users, each additional user is charge as per the Add-On User price defined
      for this plan."
          }
        }]
      }`
    
    this.renderer2.appendChild(this._document.body, scriptFAQPage);
    if (isPlatformBrowser(this.platformId)) {
      AOS.init(); /* for image animation effect */
    }
    this.titleService.setTitle(' Pricing - Document Management System | dox2U '); 
    if (isPlatformBrowser(this.platformId)) {
      this.geoLocationService.getCountryInfo().subscribe(
        (data: any) => {
          this.country = data.country_name;
          console.log("countryName", this.country)
          console.log("countryData", data);
          if (this.country === 'India') {
            this.showIndiaPrice = true;
            this.showDollarPrice = false;
          } else {
            this.showIndiaPrice = false;
            this.showDollarPrice = true;
          }
        },
        (error) => {
          console.error(error);
        }
      );
    }

    if (isPlatformBrowser(this.platformId)) {
      let accordianBodyFirst = document.getElementById("collapseOne");
      accordianBodyFirst?.classList.add('show');
    }


    this.getIPAddress1();
  }

  /**
   * Method to add cannomical tags dynamically
   */
  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  }

  onCountdownEnd(event: boolean) {
    if (event) {
      this.isLaunchTime = true;
    }
  }

  getIPAddress1(){
    if (isPlatformBrowser(this.platformId)) {
      this.http.get('https://api.ipify.org/?format=json').subscribe((res: any) => {
        this.http.get('https://ipapi.co/' + res.ip + '/json/').subscribe((resp: any) => {
          this.ipAddress = resp.ip;
          this.deviceInfo = this.deviceDetectorService.getDeviceInfo();
          // let resolution = window.innerWidth + '*' + window.innerHeight
          this.selectedCountry = resp.country_name;
          if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem('ipAddress', this.ipAddress);
            localStorage.setItem('selectedCountry', this.selectedCountry);
          }

          console.log("this.ipAddress", this.ipAddress);
          console.log("this.selectedCountry", this.selectedCountry);

          if (this.selectedCountry === 'India') {
            this.showIndiaPrice = true;
            this.showDollarPrice = false;
          } else {
            this.showIndiaPrice = false;
            this.showDollarPrice = true;
          }
        });
      });
    }


    } 


  selectItem(item: string, index: number) {
    
    this.selectedItem = index;
    this.selectedItemResp = item;

    if(index == 0) {
      this.showAnnualData = true;
      this.showTwoYearsData = false;
      this.showThreeYearsData = false;
    }

    if(index == 1) {
      this.showAnnualData = false;
      this.showTwoYearsData = true;
      this.showThreeYearsData = false;
    }

    if(index == 2) {
      this.showAnnualData = false;
      this.showTwoYearsData = false;
      this.showThreeYearsData = true;
    }
  }

  

  scrollTodCoinsSection() {
    const dcoinsElement = document.getElementById('dCoinsSection');
      if (dcoinsElement) {
          const viewportHeight = window.innerHeight;
          const elementHeight = dcoinsElement.clientHeight;
          const yOffset = (viewportHeight - elementHeight) / 2;
          const scrollOptions: ScrollToOptions = {
          top: dcoinsElement.offsetTop - yOffset + 200,
          behavior: 'smooth',
        };
          window.scrollTo(scrollOptions);
        //gettingStartedElement.scrollIntoView({ behavior: 'smooth' });
      }
  }

  expandAccordion() {
    let collapseSixElement = document.getElementById("collapseSix") as HTMLElement;
    collapseSixElement.classList.add("show");
    const collapseSix = document.getElementById('collapseSix1');
    if (collapseSix) {
      if(!this.showMore) {
        const viewportHeight = window.innerHeight;
        const elementHeight = collapseSix.clientHeight;
        const yOffset = (viewportHeight - elementHeight) / 2;
        const scrollOptions: ScrollToOptions = {
        top: collapseSix.offsetTop - yOffset +3000,
        behavior: 'smooth',
      };
        window.scrollTo(scrollOptions);
      //gettingStartedElement.scrollIntoView({ behavior: 'smooth' });
      }

      else {
        const viewportHeight = window.innerHeight;
        const elementHeight = collapseSix.clientHeight;
        const yOffset = (viewportHeight - elementHeight) / 2;
        const scrollOptions: ScrollToOptions = {
        top: collapseSix.offsetTop - yOffset +4500,
        behavior: 'smooth',
      };
        window.scrollTo(scrollOptions);
      //gettingStartedElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  

  }

}
