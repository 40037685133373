import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { EmailService } from 'src/app/email.service';
import { Location } from '@angular/common';
import { SEOService } from 'src/app/seo.service';
@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {


  isheadingElementContainer: boolean = false;
  // openedSection: string;
  Menu: string;
  isopenSectionhead: boolean;
  isSectionPolicies: boolean = false;
  isSectionDisclaimer: boolean = false;
  isSection1 : boolean = false;
  isSection2 : boolean = false;
  isSection3 : boolean = false;
  isSection4 : boolean = false;
  isSection5 : boolean = false;
  isSection6 : boolean = false;

  constructor(private el: ElementRef, private seoService: SEOService, private location: Location, private titleService: Title, private emailService: EmailService) {
    /*
      * Cannonical tag
     */
   
    this.Menu = 'Menu';
    this.isopenSectionhead = true;

    if(window.location.href.includes('customers-terms')) {
      this.isSection1 = true;
      this.isopenSectionhead = false;
      this.isSectionPolicies = false;
      this.isSectionDisclaimer = false;
    
    this.isSection2 = false;
    this.isSection3 = false;
    this.isSection4 = false;
    this.isSection5 = false;
    this.isSection6 = false;
  }

    if(window.location.href.includes('users-terms')) {
      this.isSection2 = true;
      console.log("users")
      this.isopenSectionhead = false;
      this.isSectionPolicies = false;
      this.isSectionDisclaimer = false;
      this.isSection1 = false;
    
    this.isSection3 = false;
    this.isSection4 = false;
    this.isSection5 = false;
    this.isSection6 = false;
    }

    if(window.location.href.includes('eula')) {
      this.isSection3 = true;
      this.isopenSectionhead = false;
      this.isSectionPolicies = false;
      this.isSectionDisclaimer = false;
      this.isSection1 = false;
      this.isSection2 = false;
      
      this.isSection4 = false;
      this.isSection5 = false;
      this.isSection6 = false;
    }

    if(window.location.href.includes('cookie-policy')) {
      this.isSection5 = true;
      this.isopenSectionhead = false;
      this.isSectionPolicies = false;
      this.isSectionDisclaimer = false;
      this.isSection1 = false;
      this.isSection2 = false;
      this.isSection3 = false;
      this.isSection4 = false;
      
      this.isSection6 = false;
    }

    if(window.location.href.includes('privacy-policy')) {
      this.isSection4 = true;
      this.isopenSectionhead = false;
      this.isSectionPolicies = false;
      this.isSectionDisclaimer = false;
      this.isSection1 = false;
      this.isSection2 = false;
      this.isSection3 = false;
      
      this.isSection5 = false;
      this.isSection6 = false;
    }

    if(window.location.href.includes('acceptable-use-policy')) {
      this.isSection6 = true;
      this.isopenSectionhead = false;
      this.isSectionPolicies = false;
      this.isSectionDisclaimer = false;
      this.isSection1 = false;
      this.isSection2 = false;
      this.isSection3 = false;
      this.isSection4 = false;
      this.isSection5 = false;
      
    }

    if(window.location.href.includes('disclaimer')) {
      this.isSectionDisclaimer = true;
      this.isopenSectionhead = false;
      this.isSectionPolicies = false;
   
      this.isSection1 = false;
      this.isSection2 = false;
      this.isSection3 = false;
      this.isSection4 = false;
      this.isSection5 = false;
      this.isSection6 = false;
    }
  }

  ngOnInit(): void {
    //this.meta.addTag({rel: 'canonical', content: 'https://www.dox2u.com/legal/privacy-policy'});
    this.emailService.headerOff.next(true);
    this.titleService.setTitle('Privacy Policy - Document Management System | dox2U ", "Our privacy policy describes how dox2U collects, uses and discloses information and what choices you have with respect to the information'); /** For Page Title */
    this.createLinkForCanonicalURL(); /** to add canonical tags */
  }

  /**
   * Method to add cannomical tags dynamically
   */
  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  }

 /**
   * Method to close a specified section
   */
  closeMenu() {
    let toggleItem = document.getElementById('navbarTogglerDemo01');
    toggleItem?.classList.remove('show');
    console.log("rendered5");
  }

  openSection1() {
    this.location.replaceState('/legal/customers-terms');
    this.isopenSectionhead = false;
    this.isSectionPolicies = false;
    this.isSectionDisclaimer = false;
    this.isSection1 = true;
    this.isSection2 = false;
    this.isSection3 = false;
    this.isSection4 = false;
    this.isSection5 = false;
    this.isSection6 = false;
    
    this.closeMenu();

    setTimeout(() => {
      const sectionElement = document.getElementById('privacy-policy-banner');
      if (sectionElement) {
          sectionElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
  });
  }

  openSection2() {
    this.location.replaceState('legal/users-terms');
    this.isopenSectionhead = false;
    this.isSectionPolicies = false;
    this.isSectionDisclaimer = false;
    this.isSection1 = false;
    this.isSection2 = true;
    this.isSection3 = false;
    this.isSection4 = false;
    this.isSection5 = false;
    this.isSection6 = false;
    
    this.closeMenu();

    setTimeout(() => {
      const sectionElement = document.getElementById('privacy-policy-banner');
      if (sectionElement) {
          sectionElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
  });
  }

  openSection3() {
    this.location.replaceState('legal/eula')
    this.isopenSectionhead = false;
    this.isSectionPolicies = false;
    this.isSectionDisclaimer = false;
    this.isSection1 = false;
    this.isSection2 = false;
    this.isSection3 = true;
    this.isSection4 = false;
    this.isSection5 = false;
    this.isSection6 = false;
    this.closeMenu();

    setTimeout(() => {
      const sectionElement = document.getElementById('privacy-policy-banner');
      if (sectionElement) {
          sectionElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
  });
  }

  openSection4() {
    this.location.replaceState('legal/privacy-policy')
    this.isopenSectionhead = false;
    this.isSectionPolicies = false;
    this.isSectionDisclaimer = false;
    this.isSection1 = false;
    this.isSection2 = false;
    this.isSection3 = false;
    this.isSection4 = true;
    this.isSection5 = false;
    this.isSection6 = false;
    this.closeMenu();
    
    setTimeout(() => {
      const sectionElement = document.getElementById('privacy-policy-banner');
      if (sectionElement) {
          sectionElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
  });
  }

  openSection5() {
    this.location.replaceState('legal/cookie-policy')
    this.isopenSectionhead = false;
    this.isSectionPolicies = false;
    this.isSectionDisclaimer = false;
    this.isSection1 = false;
    this.isSection2 = false;
    this.isSection3 = false;
    this.isSection4 = false;
    this.isSection5 = true;
    this.isSection6 = false;
    this.closeMenu();

    setTimeout(() => {
      const sectionElement = document.getElementById('privacy-policy-banner');
      if (sectionElement) {
          sectionElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
  });
  }

  openSection6() {
    this.location.replaceState('legal/acceptable-use-policy')
    this.isopenSectionhead = false;
    this.isSectionPolicies = false;
    this.isSectionDisclaimer = false;
    this.isSection1 = false;
    this.isSection2 = false;
    this.isSection3 = false;
    this.isSection4 = false;
    this.isSection5 = false;
    this.isSection6 = true;
    this.closeMenu();

    setTimeout(() => {
      const sectionElement = document.getElementById('privacy-policy-banner');
      if (sectionElement) {
          sectionElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
  });
  }

  openSectionhead() {
    this.location.replaceState('legal')
    this.isopenSectionhead = true;
    this.isSectionPolicies = false;
    this.isSectionDisclaimer = false;
    this.isSection1 = false;
    this.isSection2 = false;
    this.isSection3 = false;
    this.isSection4 = false;
    this.isSection5 = false;
    this.isSection6 = false;
    this.closeMenu();

    setTimeout(() => {
      const sectionElement = document.getElementById('privacy-policy-banner');
      if (sectionElement) {
          sectionElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
  });
  }

  openSectionPolicies() {
    this.location.replaceState('legal')
    this.isopenSectionhead = false;
    this.isSectionPolicies = true;
    this.isSectionDisclaimer = false;
    this.isSection1 = false;
    this.isSection2 = false;
    this.isSection3 = false;
    this.isSection4 = false;
    this.isSection5 = false;
    this.isSection6 = false;
    this.closeMenu();

    setTimeout(() => {
      const sectionElement = document.getElementById('privacy-policy-banner');
      if (sectionElement) {
          sectionElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
  });
  }

  openSectionDisclaimer() {
    this.location.replaceState('legal/disclaimer')
    this.isopenSectionhead = false;
    this.isSectionPolicies = false;
    this.isSectionDisclaimer = true;
    this.isSection1 = false;
    this.isSection2 = false;
    this.isSection3 = false;
    this.isSection4 = false;
    this.isSection5 = false;
    this.isSection6 = false;
    this.closeMenu();

    setTimeout(() => {
      const sectionElement = document.getElementById('privacy-policy-banner');
      if (sectionElement) {
          sectionElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
  });
  }

  /**
   * Method to scroll to specific element with id on click
   * @param elementId 
   */
  scrollToElement(elementId: string): void {
    const element = this.el.nativeElement.querySelector(`#${elementId}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    }
  }

  /**
   * Method to scroll to specific section
   * @param event 
   */
  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event): void {
    let navElementContainer = document.getElementById('content-section') as HTMLElement;
    this.isheadingElementContainer = this.isHeadingInViewport1(navElementContainer);
  }

  private isHeadingInViewport1(element: HTMLElement): boolean {
    const headingRect = element.getBoundingClientRect();
    return (
      headingRect.bottom >= 573 &&
      headingRect.top <= 156  
    );
  }


}
