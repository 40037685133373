import { Component, OnInit, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import * as AOS from 'aos';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { imgContentDMSAddon1, imgContentDMSAddon2, imgContentDMSAddon3, imgContentDMSAddon4, imgContentDMSAddon5 } from 'src/constants/home-content';
import {ValidateSubDomainI, WorkspaceNameI, getWsIdI } from 'src/app/interfaces/interfaces';
import { catchError , of } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import * as timeZone from '../../../assets/data/time-zone.json';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { error } from 'jquery';
import { CreateWorkspaceService } from 'src/app/create-workspace.service';
import { Title } from '@angular/platform-browser';



@Component({
  selector: 'app-download-dms-add-on',
  templateUrl: './download-dms-add-on.component.html',
  styleUrls: ['./download-dms-add-on.component.scss']
})
export class DownloadDMSAddOnComponent implements OnInit{
  downloadUrl: string = 'https://dox2uprod.blob.core.windows.net/websitedocs/dox2U%20Tally%20Add-on.EXE?sp=r&st=2024-04-22T05:48:07Z&se=2040-04-22T13:48:07Z&sv=2022-11-02&sr=b&sig=249duZiW2FTuK09tulDjEAhz9k04OuwuF5uJt9Fnly4%3D';
  @ViewChild('ownername')
  ownernameElement!: ElementRef;
  @ViewChild('firstname')
  firstnameElement!: ElementRef;
  wsDomain: string = '';
  dataDomain: string = '';
  finalDomain: string = '';
  isValidWsName: boolean = false;
  nameWsForm: FormGroup;
  WsurlForm: FormGroup;
  passwordForm: FormGroup;
  ownerForm: FormGroup;
  firstNameValidDiv: boolean = false;
  currentPage1: boolean = true;
  currentPage2: boolean = false;
  currentPage3: boolean = false;
  currentPage4: boolean = false;
  emailEmptyDiv: boolean = false;
  emailValidDiv: boolean = false;
  wsNameValidDiv: boolean = false;
  wsNameEmptyDiv: boolean = false;
  wsUrlEmptyDiv: boolean = false;
  wsUrlValidDiv: boolean = false;
  wspswdValidDiv: boolean = false;
  localWSname: string = '';
  clientTimeZone: string = '';
  timeZones: any = [];
  suggestion: any = null;
  env: string = '';
  ownerEmailDisplay: string = '';
  ownerNameDisplay: string = '';
  wsNameDisplay: string = '';
  wsURLDisplay: string = '';
  containUpperCase: boolean = false;
  wsminlengthdiv: boolean = false;
  workslink: string = '';
  currentWsOid: number = 0;
  planStartDate: string = '';
  planEndDate: number = 0;
  wsCreated: boolean = true;
  ThankyouPageShow: boolean = false;
  errorpassword: boolean = false;
  firstNameMaxLengthDiv: boolean = false;
  firstNameMinLengthDiv: boolean = false;
  startWithAlphabetdiv: boolean = false;
  wspswdEmptyDiv: boolean = false;
  

  imagePathDMSAddon1: string = '../../../assets/Image/download-dms-add-on/step1.svg'
  imagePathDMSAddon2: string = '../../../assets/Image/download-dms-add-on/step2.svg'
  imagePathDMSAddon3: string = '../../../assets/Image/download-dms-add-on/step3.svg'
  imagePathDMSAddon4: string = '../../../assets/Image/download-dms-add-on/step4.svg'
  imagePathDMSAddon5: string = '../../../assets/Image/download-dms-add-on/step5.svg'
  // imagePathDMSAddon6: string = '../../../assets/Image/download-dms-add-on/step6.svg'

  readonly imgContentDMSAddon1: typeof imgContentDMSAddon1 =  imgContentDMSAddon1;
  readonly imgContentDMSAddon2: typeof imgContentDMSAddon2 =  imgContentDMSAddon2;
  readonly imgContentDMSAddon3: typeof imgContentDMSAddon3 =  imgContentDMSAddon3;
  readonly imgContentDMSAddon4: typeof imgContentDMSAddon4 =  imgContentDMSAddon4;
  readonly imgContentDMSAddon5: typeof imgContentDMSAddon5 =  imgContentDMSAddon5;
  // readonly imgContentDMSAddon6: typeof imgContentDMSAddon6 =  imgContentDMSAddon6;
  passwordLength: boolean= false;
  containLowerCase: boolean= false;
  containSpecialCharacter: boolean= false;
  containNumber: boolean= false;
  submitted: boolean = false;
  passForm: any;
  pwBtnClick: boolean = false;
  showPassword: boolean = false;
  wsname: any;
  wsLinkFetched: string = '';
  wholeSaleService: string = '';
  workSpaceId!: number;
  workSpaceName: string = '';
  workSpaceLink: string = '';
  workEmailSpaceId: string = '';
  iconPathName: string = '';
  wsmaxlengthdiv: boolean = false;
  wsUrlMaxLengthDiv: boolean = false;
  wsUrlMinLengthDiv: boolean = false;
  wsUrlAlphabetDiv: boolean = false;
  dynamic_sp_id: any;
  spidPlan: any;
  vcid: number = 9;
  isUrlLoaded: boolean = false;
  /**
    * clipboard Data
   */
  clipboardData!: any;

  /**
   * instance for validation check in WS Name
   */
  public ShouldContainsSpecialChar = /([a-zA-Z0-9]+[-()._& ]*$)/;
  /**
   * instance for validation check in WS Name
   */
  public ShouldContainsLetter = /(^[a-zA-Z].*)/;
  spclCharWsNamediv: boolean = false;
  wsNameNotAvailable: boolean = false;
  domainAlreadyUsed: boolean = false;
  submitAdressedChecked: boolean = false;
  domain: any;
  isUniqueDomain: any;
  wsId: any;
  domainName: any;
  defaultRole: any;
  loginId: any;
  encryptedEmailID: any;
  encryptedPassword: any;
  getGenericPlanList: any;
  cookieService: any;
  createWsForm: any;
  workspaceDomain: string = '';
  errorMessage: any;
  editBtnClicked: boolean = true;

  ngOnInit(): void {
    this.titleService.setTitle(' download DMS add on | dox2U '); 
    this.downloadPdf();
    AOS.init()
    this.getCurrentEnvironments();
   // this.checkStatus();
    /**
     * getting Time zone information
     */
    this.getTimeZone();
    let windowlocation = window.location.href;
    let geturldetails = windowlocation.split('/')[3];

    const parts = geturldetails.split("?");
    console.log("parts.length", parts.length)
    const queryParameters = parts[1];
    if (parts.length === 1) {
      this.currentPage2 = true;
      this.editBtnClicked = false;
    }
    else {
      const queryParams = queryParameters.split("&");
    let ownerName = "";
    let ownerEmail = "";
    for (const param of queryParams) {
      let [key, value] = param.split("=");
      

      if (key === "ownername") {
        if(value?.includes("%20")){
          value = value.replace(/%20/g," ");
      }
        ownerName = decodeURIComponent(value);
      }

      if (key === "owneremail") {
        ownerEmail = value;
      }
    }
 
    this.nameWsForm.get('firstname')?.setValue(ownerName);
    this.nameWsForm.get('email')?.setValue(ownerEmail);   
    this.ownerEmailDisplay = ownerEmail;
    this.ownerNameDisplay = ownerName;
    }
  }
  /**
   * form validations
   * @param fb 
   * @param _apiService 
   * @param cd 
   * @param route 
   * @param logger 
   * @param http 
   */
  constructor(private fb: FormBuilder, private titleService: Title, private _apiService: CreateWorkspaceService, public cd: ChangeDetectorRef, private route: ActivatedRoute,  public logger: NGXLogger, private http: HttpClient){
    /**
     * workspace name form validation
     */
    this.nameWsForm = this.fb.group({
      wsname: ['', [Validators.required, Validators.maxLength(29), Validators.minLength(3), Validators.pattern(this.ShouldContainsSpecialChar), Validators.pattern(this.ShouldContainsLetter)]],
      firstname: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(29)]],
      email: ['', [Validators.required, Validators.email, 
                   Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),]],

    })

    /**
     * workspace url form validation
     */
    this.WsurlForm = this.fb.group({
      wsurl: ['', [Validators.required, Validators.maxLength(25), Validators.minLength(3), Validators.pattern(this.ShouldContainsLetter)]]
    })

    /**
     * workspace password form validation
     */
    this.passwordForm = this.fb.group({
      password: ['', [Validators.required,
        Validators.pattern(/^\S*$/), Validators.maxLength(99)]]
    })

    /**
     * workspace owner name form validation
     */
    this.ownerForm = this.fb.group({
      ownername: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(29)]]
    })
  }

  /**
     * toggle the password from text to hidden
     */
  toggleShowPassword(): void{
    this.showPassword = !this.showPassword;
  }

  /**
     * toggle the password from text to hidden
     */
  getInputType(): string {
    return this.showPassword ? 'text' : 'password';
  }

  /**
     * to download the pdf file on clicking click here button
     */
  downloadPdf() {
    const link = document.createElement('a');
    link.href = this.downloadUrl;
    link.download = 'file.pdf';
    link.target = '_self'; 
    link.click();
  }

    /**
     * method called on clicking next button from wrokspace name form to run validations
     */
  proceedToNext1() {
    if (this.nameWsForm.get('wsname')?.hasError('minlength')) {
      this.wsminlengthdiv = true;
    }

    if (this.nameWsForm.get('wsname')?.hasError('maxlength')) {
      this.wsmaxlengthdiv = true;
    }

    if (this.nameWsForm.get('wsname')?.hasError('required')) {
      this.wsNameEmptyDiv = true;
    }

    if(this.nameWsForm.get('wsname')?.errors?.['pattern']?.requiredPattern === this.ShouldContainsSpecialChar.toString()) {
      this.spclCharWsNamediv = true;
    }

    if(this.nameWsForm.get('wsname')?.errors?.['pattern']?.requiredPattern === this.ShouldContainsLetter.toString()) {
      this.startWithAlphabetdiv = true;
    }

    if(this.nameWsForm.get('firstname')?.hasError('required')){
      this.firstNameValidDiv = true;
    }

    if(this.nameWsForm.get('firstname')?.hasError('maxlength')){
      this.firstNameMaxLengthDiv = true;
    }

    if(this.nameWsForm.get('firstname')?.hasError('minlength')){
      this.firstNameMinLengthDiv = true;
    }
      
    if(this.nameWsForm.get('email')?.hasError('required')){
      this.emailEmptyDiv = true;
    }

    if(this.nameWsForm.get('email')?.hasError('email')){
      this.emailValidDiv = true;
    }

    if(this.nameWsForm.get('email')?.hasError('pattern')){
      this.emailValidDiv = true;
    }
    
    
    if(this.nameWsForm.valid) {
    this.onSubmitTimeZone(event);
    }
  }

  /**
     * method called on clicking next button from wrokspace url form to open create password page
     */
  async proceedtoPswdPage() {
    if(this.WsurlForm.get('wsurl')?.value.toString().length < 3) {
      this.wsUrlMinLengthDiv = true;
    }
    
    if(this.WsurlForm.get('wsurl')?.errors?.['pattern']?.requiredPattern === this.ShouldContainsLetter.toString()) {
      this.wsUrlAlphabetDiv = true;
    }

    if(this.WsurlForm.get('wsurl')?.value.toString().length > 24) {
      this.wsUrlMaxLengthDiv = true;     
    }

    if(this.WsurlForm.get('wsurl')?.hasError('required')) {
      this.wsUrlEmptyDiv = true;
    }

    if(this.WsurlForm.valid) {
    this.currentPage1 = false;
    this.currentPage2 = false;
    await this.onSubmitaddress();
    this.wsURLDisplay = this.WsurlForm.get('wsurl')?.value;
    }
  }

    /**
     * this method will route back to workspace name page on clicking back button
     */
  backToPage1() {
    this.currentPage1 = true;
    this.currentPage2 = true;
    this.editBtnClicked = false;
    this.currentPage3 = false;
    this.currentPage4 = false;
    

  }

  /**
     * this method will route back to workspace url page on clicking back button
     */
  backToPage3() {
    this.currentPage1 = false;
    this.currentPage3 = true;
    this.currentPage4 = false;
  }
  
    /**
     * this method will show the owner's name and emailID in input box to edit when clicked on edit button
     */

  showDiv() {
    this.currentPage2 = true;
    this.editBtnClicked = false;
  }

  /**
     * this method will get the environment and send api calls accordingly.
     */
  getCurrentEnvironments() {
    //let url = window.location.toString();
    let url = environment.baseUrl
    let splitBackslash = url.split("/")[2];
    if (splitBackslash === "localhost:4200") {
      this.wsDomain = ".localhost:4200";
      this.dataDomain = "localhost:4200";
      this.finalDomain = this.dataDomain;
    } else {
      var splitDot = splitBackslash.split(".");

      var getDomainName = ".";

      for (let i = 1; i < splitDot.length; i++) {
        if (i === 1)
          getDomainName += splitDot[i];
        if (i === 2)
          getDomainName += "." + splitDot[i];
        
      }
      var splitColon = getDomainName.split(':')[0];
        this.dataDomain = splitColon;
      this.finalDomain = this.dataDomain.slice(1);
      this.wsDomain = splitColon;
      
    }

  }

  /**
     * this method will reset the owner's name input box and remove the error message.
     */
  resetFirstNameDiv(event: Event) {
    this.firstNameValidDiv = false;
    this.firstNameMaxLengthDiv = false;
    this.firstNameMinLengthDiv = false;
  }


  /**
     * this method will reset the owner's email input box and remove the error message.
     */
  resetemailDiv(event: Event) {
    this.emailValidDiv = false;
    this.emailEmptyDiv = false;
  }

  /**
     * this method will reset the workspace name input box and remove the error message.
     */
  resetwsNameDiv(event: Event) {
    this.wsNameValidDiv = false;
    this.wsNameEmptyDiv = false;
    this.wsminlengthdiv = false;
    this.wsmaxlengthdiv = false;
    this.spclCharWsNamediv = false;
    this.wsNameNotAvailable = false;
    this.startWithAlphabetdiv = false;
  }

  /**
     * this method will reset the workspace URL input box and remove the error message.
     */
  resetwsUrlDiv(event: Event) {
    this.wsUrlValidDiv = false;
    this.wsUrlEmptyDiv = false;
    this.domainAlreadyUsed = false;
    this.wsUrlMinLengthDiv = false;
    this.wsUrlMaxLengthDiv = false;
    this.wsUrlAlphabetDiv = false;
  }

  /**
     * this method will reset the workspace password input box and remove the error message.
     */
  resetwspswdDiv(event: Event) {
    this.wspswdValidDiv = false;
    this.wspswdEmptyDiv = false;
  }

  /**
   * This method is used to remove special characters other than allowed ones form ws url.
   * To apply RegExp checks for validations
   * @param event 
   */
  removeSpecialCharacterswsUrl(event: any) {
    let regex = new RegExp("^[a-zA-Z0-9-]*$");
    let regPattern = /[\u0000-\u007A]+/g;
    let key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (!regex.test(key) || !regPattern.test(key)) {
      event.preventDefault();
    }
  }

  /**
   * This method will paste the copied workspace name
   * @param event 
   */

  onPasteWsaddress(event: ClipboardEvent) {
    this.clipboardData = event.clipboardData;
    let pastedText = this.clipboardData.getData('text').replace(/ /g, "");
    let regex = new RegExp("^[a-zA-Z0-9-]*$");
    var regPattern = /[\u0000-\u007A]+/g;
    if (!regex.test(pastedText.toString()) || !regPattern.test(pastedText.toString())) {
      this.cd.detectChanges();
    }
  }

  /**
   * This method is used to allow only specified special characters and block others from ws name.
   * To apply RegExp checks for validations
   * @param event 
   * @returns 
   */
  removeSpecialCharactersWSname(event: any) {
    let regex = new RegExp("^[a-zA-Z0-9 ()&._-]*$]*$");
    let regPattern = /[\u0000-\u007A]+/g;
    let key = String.fromCharCode(!event.charCode ? event.which : event.charCode);

    if (!regex.test(key) || !regPattern.test(key)) {
      event.preventDefault();
    }
    if (event.target.value.length === 0 && !/[a-zA-Z]/.test(key)) {
      event.preventDefault();
    }
  }

  /**
   * This method is used to allow only specified special characters and block others from name.
   * To apply RegExp checks for validations
   * @param event 
   * @returns 
   */
  removeSpecialCharactersName(event: any) {
    let key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
    if (event.target.value.length === 0 && /[ ]/.test(key)) {
      event.preventDefault();
    }
  }

  /**
   * This method is used to get the date and time as per the zone
   */
  getTimeZone() {
    this.env = window.location.href.split('/')[2];
    this.timeZones.push(timeZone);
    let currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (currentTimeZone == "Asia/Calcutta") {
      currentTimeZone = "Asia/Kolkata";
    }
    let index = -1;
    let tempIndex = 0;
    this.timeZones.forEach((zone: any) => {
      Array.prototype.forEach.call(zone, (value) => {
        if (value.utc[0] == currentTimeZone) {
          index = tempIndex;
          this.clientTimeZone = this.timeZones[0][index].text;
        }
        tempIndex++;


      });
    });

    if (index > 0) {
      this.clientTimeZone = this.timeZones[0][index].text;

    }


  }


  /**
   * This method is used for generating URL suggestions
   */
 onSubmitTimeZone(event?: Event) {
  this.isUrlLoaded = true;
  this.cd.detectChanges();
  this.ownerNameDisplay = this.nameWsForm.value.firstname;
  this.ownerEmailDisplay = this.nameWsForm.value.email;

    this.localWSname = this.nameWsForm.get('wsname')?.value;


    let spilltedMail = this.nameWsForm.value.email;
    let postData: WorkspaceNameI = {
      WSName: this.nameWsForm.value.wsname,
      TimeZone: this.clientTimeZone,
      SubDomain: spilltedMail?.toLowerCase()?.split("@")[1]?.split(".")[0],
      Environment: this.wsDomain
    };
    if (((!this.nameWsForm.get('wsname')?.hasError('minlength')) && (this.nameWsForm.value.wsname !== '' || null))) {

      try {
       //await this.http.post<any>('https://www.dox2udev.com:98/api/SubDomain/DomainSuggestion', postData)
       this._apiService.workSpaceName(postData).subscribe((response: any) => {
          let workSpaceName = null;
          workSpaceName = response.request.WSName;
          if (response.isSuccess) {
            this.isUrlLoaded = false;
            this.currentPage3 = true;
            this.currentPage1 = false;
            this.wsNameDisplay = this.nameWsForm.get('wsname')?.value;
            this.editBtnClicked = true;
            let format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
            this.suggestion = response.data;
            this.suggestion.forEach((element: { subDomain: { charAt: (arg0: number) => string; replace: (arg0: any, arg1: string) => any; name: string; }; }) => {
              if (format.test(element?.subDomain?.charAt(0))) {
                element.subDomain = element.subDomain.replace(element?.subDomain?.name?.charAt(0), '');
              }
            });
            let suggestionInput = null;
            //this.cd.detectChanges();
            suggestionInput = response.data[0].subDomain;
           // this.form.get('wsurl')?.setValue(suggestionInput);
           this.WsurlForm.controls['wsurl'].setValue(suggestionInput);
            this.isValidWsName = true;
            this.cd.detectChanges();
          }
          else{
            this.currentPage1 = true;
            this.wsNameNotAvailable = true;
            this.isValidWsName = false;
            this.cd.detectChanges();
            this.currentPage3 = false;
            
            this.isUrlLoaded = false;
            
           // event.preventDefault();
          }
        });
      }
      catch (err) {
        console.log(err);

      }
    }


  }

  /**
   * subDomainSet event for suggested url
   * @param  
   */
  subDomainSet(a: string) {
    this.WsurlForm.get('wsurl')?.setValue(a);
  }

  async onSubmitaddress() {
    let postData: ValidateSubDomainI = {
      WSName: this.nameWsForm.value.wsname,
      TimeZone: this.clientTimeZone,
      subDomain: `${this.WsurlForm.value.wsurl}${this.wsDomain}`,
    };

      try {
       //await this.http.post<any>('https://www.dox2udev.com:98/api/SubDomain/Validate', postData)
       await this._apiService
        .validateSubDomain(postData)
        .pipe(
          catchError((error) => {
            this.logger.error(error);
            return of(`Error: ${error}`);
          })
        )
       .toPromise().then((response: any) => {
        this.workslink = '';
        this.workslink = response.request.wsLink;
          if (response.isSuccess) {
            this.currentPage3 = false;
            this.currentPage4 = true;
            this.domainAlreadyUsed = false;
              this.submitAdressedChecked = true;
              this.cd.detectChanges();
          }

          else if(!response.isSuccess) {
            this.domainAlreadyUsed = true;
              this.submitAdressedChecked = false;
              this.cd.detectChanges();
          }

          else {

            if (response.statusCode == "C501") {

            } else if (response.statusCode == "C201") {

            } else if (response.statusCode == "C205") {

            }
          }
        });
      }
      catch (err) {
        console.log(err);

      }
    


  }

  /**
    * onEnter event
  */
  onEnterPassword(event: Event): void {
    if (this.passwordLength && this.containLowerCase && this.containSpecialCharacter && this.containNumber && this.containUpperCase) {
      event.preventDefault();
    } else {
      this.submitted = true;
    }
    this.proceedtoPswdPage();
  }

  /**
    * throw error
  */
  setPassword() {
    throw new Error('Method not implemented.');
  }

  /**
   * This method will check the strength of the password
   * @param event 
   */
  checkPasswordStrength(event: Event) {
    const passwordValue = this.passwordForm.value.password;
    this.containUpperCase = /[A-Z]/.test(passwordValue);
    this.containLowerCase = /[a-z]/.test(passwordValue);
    this.containNumber = /\d/.test(passwordValue);
    this.containSpecialCharacter = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/.test(passwordValue);
    this.passwordLength = passwordValue.length > 6;
  }

  /**
   * This method will check the password validation and 
   */
  onSubmit() {
    if (this.passwordForm.get('password')?.hasError('required')) {
      this.wspswdEmptyDiv = true;
    }

    if(this.passwordLength  && this.containLowerCase && this.containSpecialCharacter && this.containNumber && this.containUpperCase){
      this.pwBtnClick = true;
    }
  }

  /**
   * This method is used for setting plan expiry date
   * @returns 
   */
  planExpiryDate() {
    let splittedDate = this.planStartDate.split(' ');
    let day = splittedDate[1];
    let month = splittedDate[0];
    let year = splittedDate[2];
    let currentDate = new Date(`${year}-${month}-${day}`);
    currentDate.setDate(currentDate.getDate() + this.planEndDate);
    let resultDate = currentDate.toDateString();
    return resultDate;
  }


  /**
   * This method checks the status of the workspace and get the ws properties on successful response
   * 
   * Calls APi </api/Workspace/Status>
   */
  

  async createWS() {
    
        let postData: getWsIdI = {
          EmailId: this.nameWsForm.value.email?.toLowerCase(),
          WSName: "",
          TimeZone: "",
          Wslink: "",
          FullName: this.nameWsForm.value.ownerName,
          Password: '',
          IsVerified: true,
          vcid: this.vcid,
        };
  
  
        try {
           //this.http.post<any>('https://www.dox2udev.com:98/api/wstowner/WSCreate', postData)
           
            await this._apiService.getWsId(postData).pipe(
              catchError((error) => {
                this.logger.error(error);
                return of(`Error: ${error}`);
              })
            )
       .toPromise().then((response: any) => {
  
              if (response.isSuccess) {
                this.currentWsOid = response.data.wsoid;
                this.planStartDate = response.data.createdDtm
                this.cd.detectChanges();
                this.planExpiryDate();
               // this.createWorkspaceForUser();
               this.proceedtoPswdPage();
              }
  
            });
          }
            

        catch (error) {
          console.log(error);
        }
  
      }

      /**
   * create profile after password submission
   */
  wsCreateProfile() {
   
      this.spidPlan = environment.spid;
      if (this.passwordForm.get('password')?.hasError('required')) {
        this.wspswdEmptyDiv = true;
      }

    else if (!this.passwordLength || !this.containLowerCase || !this.containSpecialCharacter || !this.containNumber || !this.containUpperCase) {
      this.wspswdValidDiv = true;
      this.errorpassword = true;
    }
   
    else {
      this.ThankyouPageShow = true;
      this.wsCreated = false;
    
        let postData: any;
        postData = {
          EmailId: this.nameWsForm.value.email,
          WSName: this.nameWsForm.value.wsname,
          TimeZone: this.clientTimeZone,
          Wslink: this.workslink,
          FullName: this.nameWsForm.value.firstname,
          Password: this.passwordForm.value.password,
          IsVerified: true,
          vcid: this.vcid,
          wsoid : Number(this.currentWsOid),
          WorkspaceCreatedFrom: 'Website',
          WSCategory: 'Direct Customer WS',
          spid:  this.spidPlan,
          ipCapture: localStorage.getItem("ipAddress") ,
          browserName: localStorage.getItem("BrowserName")  + ' && Version: ' + sessionStorage.getItem('buildVersion'),
          operatingSystem: localStorage.getItem("OS"),
          deviceType: localStorage.getItem("DeviceType"),
          resolution: window.innerWidth + '*' + window.innerHeight
        };
       
        this._apiService.createWorkspace(postData).pipe(
          catchError((error) => {
            this.logger.error(error);
            return of(`Error: ${error}`);
          })
        )
       .toPromise().then((response: any) => {
          if (response.isSuccess) {
            this.workslink = response.data.wslink;
            this.domain = response.data.domainName;
            this.isUniqueDomain = response.data.isUniqueDomain;
            this.wsId = response.data.wsoid;
            this.domainName = response.data.domainName;
            this.defaultRole = response.data.defaultRole;
            this.loginId = response.data.emailId;
            this.encryptedEmailID = response.data.mid;
            this.encryptedPassword = response.data.token;
           
            this.cookieService.set('wsLink',this.workslink, {domain: environment.domain, secure: environment.httpUrl ==='https://' ? true : false, sameSite:'Lax', path:'/'});
            this.cookieService.set('password',this.encryptedPassword, {domain:environment.domain, secure: environment.httpUrl ==='https://' ? true : false, sameSite:'Lax', path:'/'});
            this.cookieService.set('emailId',this.emailId, {domain:environment.domain, secure: environment.httpUrl ==='https://' ? true : false, sameSite:'Lax', path:'/'});
            this.cookieService.set('isCreate','true', {domain: environment.domain, secure: environment.httpUrl ==='https://' ? true : false, sameSite:'Lax', path:'/'});
            var redirectionURL = environment.httpUrl + this.createWsForm.value.address + this.workspaceDomain + '/pages/dashboard';
            window.location.href = redirectionURL;
            this.cd.detectChanges();

          } else {
            console.log(error);
          }
        });

            const newTabURL = `https://${this.workslink}/onboarding/sign-in`;
            window.open(newTabURL, '_blank');
      }
  }
  emailId(arg0: string, emailId: any, arg2: { domain: string; secure: boolean; sameSite: string; path: string; }) {
    throw new Error('Method not implemented.');
  }

  /**
   * This method will scroll to step1 component on clicking on 'step 1'
   */
  scrollToStep1() {
    
    const step1Element = document.getElementById('step1');

    if (step1Element) {
      step1Element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  
  /**
   * This method will scroll to step1 component on clicking on 'step 2'
   */
  scrollToStep2() {
    const step2Element = document.getElementById('step2');

    if (step2Element) {
    step2Element.scrollIntoView({ behavior: 'smooth' });
  }
}


  /**
   * This method will scroll to step1 component on clicking on 'step 3'
   */
scrollToStep3() {
  const step3Element = document.getElementById('step3');

  if (step3Element) {
  step3Element.scrollIntoView({ behavior: 'smooth' });
  }
}


  /**
   * This method will scroll to step1 component on clicking on 'step 4'
   */
scrollToStep4() {
  const step4Element = document.getElementById('step4');

  if (step4Element) {
  step4Element.scrollIntoView({ behavior: 'smooth' });
}
}


  /**
   * This method will scroll to step1 component on clicking on 'step 5'
   */
scrollToStep5() {
  const step5Element = document.getElementById('step5');

if (step5Element) {
  const viewportHeight = window.innerHeight;
  const elementHeight = step5Element.clientHeight;
  const yOffset = (viewportHeight - elementHeight) / 2;
  const scrollOptions: ScrollToOptions = {
  top: step5Element.offsetTop - yOffset + 200,
  behavior: 'smooth',
};
  window.scrollTo(scrollOptions);
//gettingStartedElement.scrollIntoView({ behavior: 'smooth' });
}
}


  /**
   * This method will scroll to step1 component on clicking on 'step 6'
   */
scrollToStep6() {
  const step6Element = document.getElementById('step6');

if (step6Element) {
  const viewportHeight = window.innerHeight;
  const elementHeight = step6Element.clientHeight;
  const yOffset = (viewportHeight - elementHeight) / 2;
  const scrollOptions: ScrollToOptions = {
  top: step6Element.offsetTop - yOffset + 200,
  behavior: 'smooth',
};
  window.scrollTo(scrollOptions);
//gettingStartedElement.scrollIntoView({ behavior: 'smooth' });
}
  }
}
    

