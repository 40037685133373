import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { tallybackupHeroText, tallybackupHeroTextAfterLaunch } from 'src/constants/product-content';
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { ZohoService } from 'src/app/zoho.service';
import * as AOS from 'aos';
import { EmailService } from 'src/app/email.service';
import { environment } from 'src/environments/environment';
import { CustomerIOService } from 'src/app/customer-io.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-tally-backup',
  templateUrl: './tally-backup.component.html',
  styleUrls: ['./tally-backup.component.scss']
})
export class TallyBackupComponent implements OnInit {
  @ViewChild('video', { static: false }) videoElementRef!: ElementRef; 
  tallybackup_img:string="../../../../assets/Image/tally_Backup.png"
  readonly tallybackupHeroText: typeof tallybackupHeroText = tallybackupHeroText;
  readonly tallybackupHeroTextAfterLaunch: typeof tallybackupHeroTextAfterLaunch = tallybackupHeroTextAfterLaunch
  // isLoading = false;
  tallyBackupForm: FormGroup;
  firstNameValidDivTP: boolean = false;
  lastNameValidDivTP: boolean = false;
  emailValidDivEmptyTP: boolean = false;
  emailValidDivInvalidTP: boolean = false;
  phoneValidDivTP: boolean = false;
  phoneValidationCheckDivTP: boolean = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  separateDialCode!: false;
  PhoneNumberFormat = PhoneNumberFormat;
  waitingFormSubmitted: boolean = false;
  waitingFormShow: boolean = true;
  countryNameTP: string = 'India';
  dialCodeTP: number = 91;
  contactNumberTP: any;
  isLaunchTime: boolean = false;
  userInteracted: boolean = false;
  videoUrl: string = 'https://www.dox2u.com/e-docs/tally_backup.mp4';

  firstNameValidDiv: boolean = false;
  lastNameValidDiv: boolean = false;
  emailValidDivEmpty: boolean = false;
  emailValidDivInvalid: boolean = false;
  phoneValidDiv: boolean = false;
  submittedForm: boolean = false;
  phoneValidationCheckDiv: boolean = false;
  dmsForm: FormGroup;
  countryName: string = 'India';
  dialCode: number = 91;
  contactNumber: any;
  // videoElement!: HTMLVideoElement;

  constructor(private fb: FormBuilder, private titleService: Title, private zohoService: ZohoService, private emailService: EmailService, private customerIOService: CustomerIOService) {
    this.tallyBackupForm = this.fb.group({
      firstname: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email,
        Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),]],
        phone: ['', [Validators.required, Validators.maxLength(15)]],
    });
     /*
     form validations
    */
     this.dmsForm = this.fb.group({
      firstname: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email,
        Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),]],
      phone: ['', [Validators.required, Validators.maxLength(15)]],
    });
    if (typeof window !== 'undefined') {
        this.SearchCountryField = SearchCountryField;
        this.CountryISO = CountryISO;
        this.PhoneNumberFormat = PhoneNumberFormat;
    }
  }
  ngOnInit(): void {
    this.emailService.headerOff.next(true);
    console.log("tally backup page");
    if (typeof window !== 'undefined') {
      AOS.init();
      this.titleService.setTitle(' dox2U - Automated Tally Backup Solution');
      setTimeout(() => {
        let accordianBodyFirst = document.getElementById("collapseOne") as HTMLElement;
        console.log("accordianBodyFirst", accordianBodyFirst)
        accordianBodyFirst?.classList.add('show');
      }, 200)

    }

    
    // throw new Error('Method not implemented.');
  }

  ngAfterViewInit() {
    // This ensures that the video element is available after the view has been initialized
    console.log(this.videoElementRef.nativeElement);
  }


  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event): void {
    if (this.videoElementRef) {
      const videoElement = this.videoElementRef.nativeElement;
      const isVideoInViewport = this.isElementInViewport(videoElement);
      console.log("videoelem", isVideoInViewport);
      if (!isVideoInViewport) {
        videoElement.pause();
      }
    }
    
  }

  private isElementInViewport(element: HTMLElement): boolean {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

   /**
   * To check countrywise phone number validation 
   */

   checkPhonenoValidation() {
    // let phoneNumber = this.dmsForm.get('phone')?.value;
    // let countryCode = (localStorage.getItem('countryCode'))?.toUpperCase(); 

  //  if(!isPhonenoValid) {
  //   this.phoneValidationCheckDiv = true;
  //   event?.preventDefault();
  //  }
  }
  
validatePhoneNumber(phoneNumber: string, countryCode: any) {
  // const parsedNumber = this.phoneNumberUtil.parseAndKeepRawInput(phoneNumber, countryCode);
  //   const isValidNumber = this.phoneNumberUtil.isValidNumber(parsedNumber);
  //   console.log("phone number is", parsedNumber, isValidNumber);
  //   const numberType = this.phoneNumberUtil.getNumberType(parsedNumber);
  //   return isValidNumber;

    
}

  scrollToWhyChooseDox() {
    const doxcompetition = document.getElementById('dox-competition');
    if (doxcompetition) {
      const viewportHeight = window.innerHeight;
        const elementHeight = doxcompetition.clientHeight;
        const yOffset = (viewportHeight - elementHeight) / 2 + 200;
        const scrollOptions: ScrollToOptions = {
        top: doxcompetition.offsetTop - yOffset,
        behavior: 'smooth',
      };
        window.scrollTo(scrollOptions);
    }
  }

  onCountryChangeTP(event: any) {
    this.countryNameTP = event.name;
    this.dialCodeTP = event.dialCode;
    this.contactNumberTP = event.e164Number;
    console.log('Selected Country ISO:', event.iso2);
    console.log('Selected Country Name:', this.countryName);
    console.log('Selected Country Dial Code:', this.dialCode);
  }

  resetFirstNameDivTP(event: Event) {
    this.firstNameValidDivTP = false;
  }

  onCountryChange(event: any) {
    this.countryName = event.name;
    this.dialCode = event.dialCode;
    this.contactNumber = event.e164Number;
    console.log('Selected Country ISO:', event.iso2);
    console.log('Selected Country Name:', this.countryName);
    console.log('Selected Country Dial Code:', this.dialCode);
  }

  resetLastNameDivTP(event: Event) {
    this.lastNameValidDivTP = false;
  }

  resetEmailDivTP(event: Event) {
    this.emailValidDivEmptyTP = false;
    this.emailValidDivInvalidTP = false;
  }

  resetPhoneDivTP(event: Event) {
    this.phoneValidDivTP = false;
    this.phoneValidationCheckDivTP = false;
  }

  onSubmitTallyBackup(event: Event): void {
    if(this.tallyBackupForm.get('firstname')?.hasError('required')) {
      this.firstNameValidDivTP = true;
    }
  
    if(this.tallyBackupForm.get('lastname')?.hasError('required')) {
      this.lastNameValidDivTP = true;
    }

    if(this.tallyBackupForm.get('email')?.hasError('required')) {
      this.emailValidDivEmptyTP = true;
    }
  
    if(this.tallyBackupForm.get('email')?.hasError('email')) {
      this.emailValidDivInvalidTP = true;
    }
  
    if(this.tallyBackupForm.get('email')?.hasError('pattern')) {
      this.emailValidDivInvalidTP = true;
    }

    if(this.tallyBackupForm.controls['phone'].invalid) {
      this.phoneValidationCheckDivTP = true;
      event?.preventDefault();
     }
  
    if(this.tallyBackupForm.get('phone')?.hasError('required')) {
      this.phoneValidDivTP = true;
      this.phoneValidationCheckDivTP = false;

      
    }
  
      if (this.tallyBackupForm.valid && !this.phoneValidationCheckDivTP) {
        console.log("submitted")
          this.waitingFormSubmitted = true;
          this.waitingFormShow = false;
          const phoneValueTP = this.tallyBackupForm.value.phone;
          //  contactNumber = `+${this.dialCode}${phoneValue}`;
          this.contactNumberTP = phoneValueTP.internationalNumber;
            console.log("contactNumber", this.contactNumberTP)
            console.log("dialCode", this.dialCodeTP)
            console.log("phoneValue", this.contactNumberTP)
            console.log("countryName", this.countryNameTP)
  
          /*
          payload for zoho service
        */
          const firstName = this.tallyBackupForm.value.firstname;
          const lastName = this.tallyBackupForm.value.lastname;
          const fullName = `${firstName} ${lastName}`;
          const email = this.tallyBackupForm.value.email;
          const WS_SubscriptionStatus = "";
          const leadSource = 'WS-Downloadtally-Bkp';
          const address1 = '';
          const address2 = '';
          const phoneNo = this.contactNumberTP;
          const imgContent_security = '';
          const state = '';
          const country = this.countryNameTP;
          const orgName = this.tallyBackupForm.value.companyname;
          const companySize = '';
          const jobTitle = '';
          const salutation = '';
          const message1 = '';
          const message2 = '';
  
          /*
            function to send data to zoho service
          */
  
          this.zohoService.sendUserData(firstName, lastName, fullName, 
            email, 
            WS_SubscriptionStatus, 
            leadSource, 
            address1,
            address2,
            phoneNo,
            imgContent_security,
            state,
            country,
            orgName,
            companySize,
            jobTitle,
            salutation,
            message1,
            message2)
          .subscribe(
            (response) => {
              console.log('zoho lead sent:', response);
            },
            error => {
              console.error('Email Error:', error);
            }
          );
  
        }
  
        
      }

      onCountdownEnd(event: boolean) {
        if (event) {
          this.isLaunchTime = true;
        }
      }
      
      pauseVideo() {
        this.userInteracted = !this.userInteracted;
      }

   
   /*
  This method will send the payload for zoho, customer.io and email service on successful validations of all input fields
  */
  onSubmit() {
    if(this.dmsForm.get('firstname')?.hasError('required')) {
      this.firstNameValidDivTP = true;
    }
  
    if(this.dmsForm.get('lastname')?.hasError('required')) {
      this.lastNameValidDivTP = true;
    }
  
    if(this.dmsForm.get('email')?.hasError('required')) {
      this.emailValidDivEmptyTP = true;
    }
  
    if(this.dmsForm.get('email')?.hasError('email')) {
      this.emailValidDivInvalidTP = true;
    }
  
    if(this.dmsForm.get('email')?.hasError('pattern')) {
      this.emailValidDivInvalidTP = true;
    }
  
    if(this.dmsForm.controls['phone'].invalid) {
      this.phoneValidationCheckDivTP = true;
        event?.preventDefault();
      
     }
  
    if(this.dmsForm.get('phone')?.hasError('required')) {
      this.phoneValidDivTP = true;
      this.phoneValidationCheckDivTP = false;
    }
  
      if (this.dmsForm.valid && !this.phoneValidationCheckDivTP) {
          this.submittedForm = true;
          
          //contactNumber = "+" + this.dialCode + this.dmsForm.value.phone;
          const phoneValue = this.dmsForm.value.phone;
        //  contactNumber = `+${this.dialCode}${phoneValue}`;
        this.contactNumber = phoneValue.internationalNumber;
          console.log("contactNumber", this.contactNumber)
          console.log("dialCode", this.dialCode)
          console.log("phoneValue", this.contactNumber)
          console.log("countryName", this.countryName)
  
  
          /*
          payload for zoho service
        */
          const firstName = this.dmsForm.value.firstname;
          const lastName = this.dmsForm.value.lastname;
          const fullName = `${firstName} ${lastName}`;
          const email = this.dmsForm.value.email;
          const WS_SubscriptionStatus = "";
          const leadSource = 'WS-Downloadtally-Bkp';
          const address1 = '';
          const address2 = '';
          const phoneNo = this.contactNumber;
          const imgContent_security = '';
          const state = '';
          const country = this.countryName;
          const orgName = '';
          const companySize = '';
          const jobTitle = '';
          const salutation = '';
          const message1 = '';
          const message2 = '';
  
          /*
            function to send data to zoho service
          */
  
          this.zohoService.sendUserData(firstName, lastName, fullName, 
            email, 
            WS_SubscriptionStatus, 
            leadSource, 
            address1,
            address2,
            phoneNo,
            imgContent_security,
            state,
            country,
            orgName,
            companySize,
            jobTitle,
            salutation,
            message1,
            message2)
          .subscribe(
            (response) => {
              console.log('zoho lead sent:', response);
            },
            error => {
              console.error('Email Error:', error);
            }
          );
  
          /*
          payload for email service
          */ 
          const recipientEmail = this.dmsForm.value.email;
          const messageId = environment.DownloadTallyMessageID;
          const { lastname, firstname } = this.dmsForm.value;
          const ownerFullName = firstname+' '+lastname;
          console.log ('fullname', ownerFullName);
          
  
          const messageData = { First_Name: this.dmsForm.value.firstname,  
                                ownernName: ownerFullName,
                                ownerEmail: this.dmsForm.value.email};
  
            /*
            function to send data to email service
          */
          this.emailService.sendEmail(recipientEmail, messageId, messageData, email)
            .subscribe(
              (response) => {
                console.log('Email sent:', response);
              },
              (error) => {
                console.error('Email Error:', error);
              }
            );
  
              
          /*
            function to send data to customer.io
          */
              this.customerIOService.updateUserLeadSource(email, leadSource)
                .subscribe(
                  (response) => {
                    console.log('User lead source updated successfully:', response);
                  },
                  (error) => {
                    console.error('Error updating user lead source:', error);
                  }
                );
        }
  
        
      }
}
