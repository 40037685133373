import { Component, ElementRef, HostListener, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { guestAccessHero, isitSafe, removeAccess, shareWithGuest, whatsappIntegrationText } from 'src/constants/product-content';
import { SEOService } from 'src/app/seo.service';

@Component({
  selector: 'app-guest-access',
  templateUrl: './guest-access.component.html',
  styleUrls: ['./guest-access.component.scss']
})
export class GuestAccessComponent implements OnInit {
  ngOnInit(): void {
    //this.meta.addTag({rel: 'canonical', content: 'https://www.dox2u.com/guest-access'});
    this.createLinkForCanonicalURL() /** to add canonical tags */
    let scriptListItem = this.renderer2.createElement('script');
    scriptListItem.type = `application/ld+json`;
    scriptListItem.text = `{
      "@context": "https://schema.org/", 
    "@type": "BreadcrumbList", 
    "itemListElement": [{
      "@type": "ListItem", 
      "position": 1, 
      "name": "Home page",
      "item": "https://dox2u.com/"  
    },{
      "@type": "ListItem", 
      "position": 2, 
      "name": "Guest Access page",
      "item": "https://www.dox2u.com/guest-access"  
    }]
      }`
    
    this.renderer2.appendChild(this._document.body, scriptListItem);
    
    this.titleService.setTitle('Ease Your Document Sharing with Guest User Access | dox2U ","With the Guest User Access facility, dox2U lets you share documents with external guests easily and conveniently. Explore How.'); /** For Page Title */
  }

  constructor(private titleService: Title, private seoService: SEOService, private renderer2: Renderer2, @Inject(DOCUMENT) private _document: Document){
     /*
      * Cannonical tag
     */
          
  }

  readonly guestAccessHeroText: typeof guestAccessHero =  guestAccessHero;
  readonly shareWithGuest: typeof shareWithGuest =  shareWithGuest;
  readonly revokeAccess: typeof removeAccess = removeAccess ;
  readonly It_Is_SAFE: typeof isitSafe =  isitSafe;
  readonly whatsappIntegrationText: typeof whatsappIntegrationText =  whatsappIntegrationText;
  // readonly mfGuest: typeof isitSafe =  mfGuest1;
  // readonly mfGuest2: typeof isitSafe =  mfGuest2;
  // readonly mfGuest3: typeof isitSafe =  mfGuest3;


  guestAccessHero_img:string="../../../../assets/Image/guest-access/dox2u_guest_access.svg"
  guest_share_img:string="../../../../assets/Image/guest-access/dox2u_guest_share.svg"
  guest_revoke_img:string="../../../../assets/Image/guest-access/dox2u_guest_revoke.svg"
  guest_safe_img:string="../../../../assets/Image/guest-access/dox2u_guest_safe.svg"
  morefeature1:string="../../../../assets/Image/guest-access/dox2u_guest_safe.svg"
  morefeature2:string="../../../../assets/Image/guest-access/dox2u_guest_safe.svg"
  morefeature3:string="../../../../assets/Image/guest-access/dox2u_guest_safe.svg"
  whatsappIntegrationImg: string=""
  videoURL: string = 'https://www.dox2u.com/e-docs/Guest_share_product-fruit.mp4';

  @ViewChild('myVideo') videoElement!: ElementRef<HTMLVideoElement>;

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event): void {
    if (this.videoElement) {
      const isVideoInViewport = this.isElementInViewport(this.videoElement.nativeElement);
      if (!isVideoInViewport) {
        this.videoElement.nativeElement.pause();
      }
    }
  }

  /**
   * Method to add cannomical tags dynamically
   */
  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  }

  private isElementInViewport(element: HTMLElement): boolean {
    const rect = element.getBoundingClientRect();
    
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
    
  }
}
