import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ResellerContent, imgContentReseller1, imgContentReseller2, imgContentReseller3, imgContentReseller4 } from 'src/constants/home-content';
import * as AOS from 'aos';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ZohoService } from 'src/app/zoho.service';
import { CustomerIOService } from 'src/app/customer-io.service';
import { EmailService } from 'src/app/email.service';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';
import { PhoneNumberUtil } from "google-libphonenumber";
import { CountryISO, PhoneNumberFormat, SearchCountryField } from 'ngx-intl-tel-input';
import { SEOService } from 'src/app/seo.service';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})
export class PartnersComponent implements OnInit {
  channelPartner: FormGroup;

  firstNameCValidDiv: boolean = false;
  lastNameCValidDiv: boolean = false;
  organizationCValidDiv: boolean = false;
  tallyemailCValidDiv: boolean = false;
  phoneCValidDiv: boolean = false;
  countryCValidDiv: boolean = false;
  tallyemailCEmptyDiv: boolean = false;
  tallyemailCInvalidDiv: boolean = false;
  partnerFormSubmitted: boolean = false;
  partnerformShow : boolean = true;
  partnerWithUsFormSubmitted: boolean= false;
  selectedFlagDiv: boolean = false;
  phoneValidationCheckDiv: boolean = false;
  phoneNumberUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();
  SearchCountryField = SearchCountryField;
	CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  separateDialCode!: false;
  @ViewChild('selectedFlag', { static: false })
  selectedFlag!: ElementRef;

  partnerForm: FormGroup;
  firstNamePValidDiv: boolean = false;
  lastNamePValidDiv: boolean = false;
  organizationPValidDiv: boolean = false;
  tallyemailPValidDiv: boolean = false;
  phonePValidDiv: boolean = false;
  phoneCValidationCheckDiv: boolean = false;
  countryPValidDiv: boolean = false;
  tallyemailPEmptyDiv: boolean = false;
  tallyemailPInvalidDiv: boolean = false;
  partnerFormShow: boolean = true;
  countryNameCP: string = 'India';
  dialCodeCP: Number = 91;
  contactNumberCP: any;
  countryNameP: string = 'India';
  dialCodeP: Number = 91;
  contactNumberP: any;




  constructor(private CPform: FormBuilder, private seoService: SEOService, private partnerfb: FormBuilder, private titleService: Title, private emailService: EmailService, private zohoService: ZohoService,private customerIOService: CustomerIOService,){
    /*
      * Cannonical tag
     */

    this.channelPartner = this.CPform.group({
      firstnameC: ['', [Validators.required]],
      lastnameC: ['', [Validators.required]],
      organizationC: ['', [Validators.required]],
      tallyemailC: ['', [Validators.required, Validators.email,
        Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),]],
     countryC: ['', [Validators.required]],
      phoneC: ['', [Validators.required, Validators.maxLength(15)]],
    });

    this.partnerForm = this.partnerfb.group({
      firstnameP: ['', [Validators.required]],
      lastnameP: ['', [Validators.required]],
      organizationP: ['', [Validators.required]],
      tallyemailP: ['', [Validators.required, Validators.email,
        Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),]],
      countryP: ['', [Validators.required]],
      phone: ['', [Validators.required, Validators.maxLength(15)]],
    });
  }

  ngOnInit() {
    //this.meta.addTag({rel: 'canonical', content: 'https://www.dox2u.com/partners'});
    if (typeof window !== 'undefined') {
      AOS.init(); /* for image animation effect */
    }   
    this.titleService.setTitle("Expand Your Reach and Revenues: Partner with dox2u's Document Management Solution"); /** For Page Title */
    this.createLinkForCanonicalURL(); /** to add canonical tags */
  }

  ResellerPathLeft : string = '../../../assets/Image/scale-business.svg'

  readonly ResellerContent: typeof ResellerContent =  ResellerContent;

  imagePathReseller1: string = '../../../assets/Image/rapid.svg';
  imagePathReseller2: string = '../../../assets/Image/Affordability & Simplicity.svg'
  imagePathReseller3: string = '../../../assets/Image/Features & Security.svg'
  imagePathReseller4: string = '../../../assets/Image/Dedicated Support.svg'

  readonly imgContentReseller1: typeof imgContentReseller1 =  imgContentReseller1;
  readonly imgContentReseller2: typeof imgContentReseller2 =  imgContentReseller2;
  readonly imgContentReseller3: typeof imgContentReseller3 =  imgContentReseller3;
  readonly imgContentReseller4: typeof imgContentReseller4 =  imgContentReseller4;

  // checkPhonenoValidation() {
  //   let phoneNumber = this.partnerForm.get('phone')?.value;
  //   let countryCode = (localStorage.getItem('countryCode'))?.toUpperCase(); 
  //   let isPhonenoValid = this.validatePhoneNumber(phoneNumber, countryCode);

  //  if(!isPhonenoValid) {
  //   this.phoneValidationCheckDiv = true;
  //  }
  // }
  /**
   * Method to add cannomical tags dynamically
   */
  createLinkForCanonicalURL() {
    this.seoService.createLinkForCanonicalURL();
  }

  checkPhonenoValidationChannel() {
  //   let phoneNumber = this.channelPartner.get('phoneC')?.value;
  //   let countryCode = (localStorage.getItem('countryCode'))?.toUpperCase(); 
  //   let isPhonenoValid = this.validatePhoneNumber(phoneNumber, countryCode);

  //  if(!isPhonenoValid) {
  //   this.phoneCValidationCheckDiv = true;
  //  }
  }
  

  validatePhoneNumber(phoneNumber: string, countryCode: any) {
    const parsedNumber = this.phoneNumberUtil.parseAndKeepRawInput(phoneNumber, countryCode);
      const isValidNumber = this.phoneNumberUtil.isValidNumber(parsedNumber);
      const numberType = this.phoneNumberUtil.getNumberType(parsedNumber);
      console.log(parsedNumber, isValidNumber, numberType)
      return isValidNumber;

      
  }
  
  resetFirstNameCDiv(event: Event) {
    this.firstNameCValidDiv = false;
  }

  resetLastNameCDiv(event: Event) {
    this.lastNameCValidDiv = false;
  }

  resetOrganizationCDiv(event: Event) {
    this.organizationCValidDiv = false;
  }

  resetTallyEmailCDiv(event: Event) {
    this.tallyemailCInvalidDiv = false;
    this.tallyemailCEmptyDiv = false;

  }

  resetPhoneCDiv(event: Event) {
    this.phoneCValidDiv = false;
    this.phoneCValidationCheckDiv = false;
  }

  resetCountryCDiv(event: Event) {
    this.countryCValidDiv = false;
  }

  //partner-with-us

  resetFirstNamePDiv(event: Event) {
    this.firstNamePValidDiv = false;
  }

  resetLastNamePDiv(event: Event) {
    this.lastNamePValidDiv = false;
  }

  resetOrganizationPDiv(event: Event) {
    this.organizationPValidDiv = false;
  }

  resetTallyEmailPDiv(event: Event) {
    this.tallyemailPInvalidDiv = false;
    this.tallyemailPEmptyDiv = false;

  }

  resetPhoneCPDiv(event: Event) {
    this.phonePValidDiv = false;
    this.phoneValidationCheckDiv = false;
  }

  resetCountryPDiv(event: Event) {
    this.countryPValidDiv = false;
  }

  onCountryChangeCP(event: any) {
      this.countryNameCP = event.name;
      this.dialCodeCP = event.dialCode;
      this.contactNumberCP = event.e164Number;
      console.log('Selected Country ISO:', event.iso2);
      console.log('Selected Country Name:', this.countryNameCP);
      console.log('Selected Country Dial Code:', this.dialCodeCP);
  }
// end p-w-u
  onSubmit() {
    if(this.channelPartner.get('firstnameC')?.hasError('required')) {
      this.firstNameCValidDiv = true;
    }
  
    if(this.channelPartner.get('lastnameC')?.hasError('required')) {
      this.lastNameCValidDiv = true;
    }
  
    if(this.channelPartner.get('organizationC')?.hasError('required')) {
      this.organizationCValidDiv = true;
    }
  
    if(this.channelPartner.get('tallyemailC')?.hasError('email')) {
      this.tallyemailCInvalidDiv = true;
    }

    if(this.channelPartner.get('tallyemailC')?.hasError('required')) {
      this.tallyemailCEmptyDiv = true;
    }
  
    if(this.channelPartner.get('tallyemailC')?.hasError('required')) {
      this.countryCValidDiv = true;
    }

   if(this.channelPartner.controls['phoneC'].invalid) {
    this.phoneCValidationCheckDiv = true;
    event?.preventDefault();
   }

    if(this.channelPartner.get('phoneC')?.hasError('required')) {
      this.phoneCValidDiv = true;
      this.phoneCValidationCheckDiv = false;
    }

   // this.checkPhonenoValidationChannel();

  //  let phoneNumber = this.channelPartner.get('phoneC')?.value;
  //   let countryCode = (localStorage.getItem('countryCode'))?.toUpperCase(); 
  //   let isPhonenoValid = this.validatePhoneNumber(phoneNumber, countryCode);


    // let dialCode = localStorage.getItem("DialCode");
    // let contactNumber = '';
        
    if (this.channelPartner.valid && !this.phoneCValidationCheckDiv) {
      this.partnerFormSubmitted = true;
      this.partnerformShow = false;
      // dialCode = dialCode || "91";
      // contactNumber = "+" + dialCode + this.channelPartner.value.phoneC;
      const phoneValueCP = this.channelPartner.value.phoneC;
      this.contactNumberCP = phoneValueCP.internationalNumber;
              console.log("contactNumber", this.contactNumberCP)
              console.log("dialCode", this.dialCodeCP)
              console.log("phoneValue", this.contactNumberCP)
              console.log("countryName", this.countryNameCP)

      const firstName = this.channelPartner.value.firstnameC;
        const lastName = this.channelPartner.value.lastnameC;
        const fullName = `${firstName} ${lastName}`;
        const email = this.channelPartner.value.tallyemailC;
        const orgName = this.channelPartner.value.organizationC;
        const phoneNo = this.contactNumberCP;
        const country = this.channelPartner.value.countryC;
        const WS_SubscriptionStatus = "";
        const leadSource = "WS-PartnerRequest";
        const address1 = '';
        const address2 = '';        
        const imgContent_security = '';
        const state = '';
        const companySize = '';
        const jobTitle = '';
        const salutation = '';
        const message1 = '';
        const message2 = '';



      this.zohoService.sendUserData(firstName, lastName, fullName, 
        email, 
        WS_SubscriptionStatus, 
        leadSource, 
        address1,
        address2,
        phoneNo,
        imgContent_security,
        state,
        country,
        orgName,
        companySize,
        jobTitle,
        salutation,
        message1,
        message2)
      .subscribe(
        (response) => {
          console.log('zoho lead sent:', response);
        },
        error => {
          console.error('Email Error:', error);
        }
      );

      const recipientEmail = this.channelPartner.value.tallyemailC;
      const messageId = environment.PartnerFormsMessageID;
      const messageData = { First_Name: this.channelPartner.value.firstnameC };

  
      this.emailService.sendEmail(recipientEmail, messageId, messageData, email)
        .subscribe(
          (response) => {
            console.log('Email sent:', response);
          },
          (error) => {
            console.error('Email Error:', error);
          }
        );

          
          
          this.customerIOService.updateUserLeadSource(email, leadSource)
            .subscribe(
              (response) => {
                console.log('User lead source updated successfully:', response);
              },
              (error) => {
                console.error('Error updating user lead source:', error);
              }
            );
    }
  }

  onCountryChangeP(event: any) {
    this.countryNameP = event.name;
    this.dialCodeP = event.dialCode;
    this.contactNumberP = event.e164Number;
    console.log('Selected Country ISO:', event.iso2);
    console.log('Selected Country Name:', this.countryNameP);
    console.log('Selected Country Dial Code:', this.dialCodeP);
  }

  onSubmitParther() {
    
    if(this.partnerForm.get('firstnameP')?.hasError('required')) {
      this.firstNamePValidDiv = true;
    }
  
    if(this.partnerForm.get('lastnameP')?.hasError('required')) {
      this.lastNamePValidDiv = true;
    }
  
    if(this.partnerForm.get('organizationP')?.hasError('required')) {
      this.organizationPValidDiv = true;
    }
  
    if(this.partnerForm.get('tallyemailP')?.hasError('email')) {
      this.tallyemailPInvalidDiv = true;
    }

    if(this.partnerForm.get('tallyemailP')?.hasError('pattern')) {
      this.tallyemailPInvalidDiv = true;
    }

    if(this.partnerForm.get('tallyemailP')?.hasError('required')) {
      this.tallyemailPEmptyDiv = true;
    }
  
    if(this.partnerForm.get('countryP')?.hasError('required')) {
      this.countryPValidDiv = true;
    }
    
   if(this.partnerForm.controls['phone'].invalid) {
    this.phoneValidationCheckDiv = true;
    event?.preventDefault();
   }

    if(this.partnerForm.get('phone')?.hasError('required')) {
      this.phonePValidDiv = true;
      this.phoneValidationCheckDiv = false;
    }

   // this.checkPhonenoValidation();

  //  let phoneNumber = this.partnerForm.get('phone')?.value;
  //   let countryCode = (localStorage.getItem('countryCode'))?.toUpperCase(); 
  //   let isPhonenoValid = this.validatePhoneNumber(phoneNumber, countryCode);


    // let dialCode = localStorage.getItem("DialCode");
    // let contactNumber = '';
  
      if (this.partnerForm.valid && !this.phoneValidationCheckDiv) {
        this.partnerWithUsFormSubmitted = true;
        this.partnerFormShow = false;
        // dialCode = dialCode || "91";
        // contactNumber = "+" + dialCode + this.partnerForm.value.phone;
        const phoneValueP = this.partnerForm.value.phone;
        this.contactNumberP = phoneValueP.internationalNumber;
        console.log("contactNumber", this.contactNumberP)
        console.log("dialCode", this.dialCodeP)
        console.log("phoneValue", this.contactNumberP)
        console.log("countryName", this.countryNameP)

        const firstName = this.partnerForm.value.firstnameP;
        const lastName = this.partnerForm.value.lastnameP;
        const fullName = `${firstName} ${lastName}`;
        const email = this.partnerForm.value.tallyemailP;
        const orgName = this.partnerForm.value.organizationP;
        const phoneNo = this.contactNumberP;
        const country = this.partnerForm.value.countryP;
        const WS_SubscriptionStatus = "";
        const leadSource = "WS-PartnerRequest";
        const address1 = '';
        const address2 = '';        
        const imgContent_security = '';
        const state = '';
        const companySize = '';
        const jobTitle = '';
        const salutation = '';
        const message1 = '';
        const message2 = '';

        this.zohoService.sendUserData(firstName, lastName, fullName, 
          email, 
          WS_SubscriptionStatus, 
          leadSource, 
          address1,
          address2,
          phoneNo,
          imgContent_security,
          state,
          country,
          orgName,
          companySize,
          jobTitle,
          salutation,
          message1,
          message2)
        .subscribe(
          (response) => {
            console.log('zoho lead sent:', response);
          },
          error => {
            console.error('Email Error:', error);
          }
        );

        const recipientEmail = this.partnerForm.value.tallyemailP;
        const messageId = environment.PartnerFormsMessageID;
        const messageData = { First_Name: this.partnerForm.value.firstnameP };

  
        this.emailService.sendEmail(recipientEmail, messageId, messageData, email)
        .subscribe(
          (response) => {
            console.log('Email sent:', response);
          },
          (error) => {
            console.error('Email Error:', error);
          }
        );

        this.customerIOService.updateUserLeadSource(email, leadSource)
              .subscribe(
                (response) => {
                  console.log('User lead source updated successfully:', response);
                },
                (error) => {
                  console.error('Error updating user lead source:', error);
                }
              );
          
        }
  
        
      }
}

